import { FC, useEffect } from "react";

import { getProfileData, useAppDispatch, useAppSelector } from "./redux";

import Cookies from "js-cookie";
import RoutesComponent from "./routes";
import { selectLoading } from "./redux";
import { useSelector } from "react-redux";
import Spinner from "./components/ui/Spinner";
import { useLocation } from "react-router-dom";

const App: FC = () => {
    const dispatch = useAppDispatch();

    const loadingIsActive = useAppSelector(selectLoading);

    useEffect(() => {
        if (Cookies.get('token')) {
            // dispatch(useSelector(getProfileData));
        }
    }, [ dispatch ]);


    return (
        <>
            <RoutesComponent />

            { loadingIsActive && (
                <div className="spinner__wrapper">
                    <div className="spinner-block">
                        <Spinner />
                    </div>
                </div>
            ) }
        </>
    )
}
export default App;
