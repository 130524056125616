import React, { FC } from "react";

import {
    MenuIcon,
    SearchIcon,
    NotificationIcon, ArrowExitIcon
} from "../../img";
import { isMd } from "../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface HeaderHomeProps {
    openMenu: () => void;
    title?: string,
}

const HeaderHome: FC<HeaderHomeProps> = ({openMenu, title}) => {
    const isMobile: boolean = window.screen.width < 1020;
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    return (
        <header className={ "header" }>
            <div className="header__items">
                <div className={ "container" }>
                    <div className={ "header__item" }>
                        { isMobile && (
                            <>
                                { title ?
                                    (
                                        <div className='header__exitIcon' onClick={handleNavigation}>
                                            <ArrowExitIcon />
                                        </div>
                                    )
                                    :
                                    (
                                        <img onClick={() => navigate('profile')} src={ '/testImages/people/Image-1.png' } className='header__profileIcon' />
                                    )
                                }
                            </>
                        ) }

                        <div className={ "header__left" }>
                            { !isMobile && (
                                <button
                                    type={ "button" }
                                    className={ "header__menu" }
                                    onClick={ openMenu }
                                >
                                    <MenuIcon color={ "#000" } />
                                </button>
                            ) }

                            {isMobile && title ? (
                                <div className='header-center__title'>
                                    { title }
                                </div>
                            ) : (
                                <Link className={ "header__logo" } to={ "/" }>
                                    <img
                                        src="/images/logo.png"
                                        alt="logo"
                                    />
                                </Link>
                            ) }
                        </div>

                        <div className={ "header__right" }>
                            <div className={ "header__other" }>
                                { !isMobile && (
                                    <div className={ "header__search" }>
                                        <button
                                            type={ "button" }
                                            className={ "btn-icon" }
                                        >
                                            <SearchIcon color={ '#000' } />
                                        </button>
                                    </div>
                                ) }

                                <div className={ "header__notification" }>
                                    <button
                                        type={ "button" }
                                        className={ "btn-icon" }
                                    >
                                        <NotificationIcon color='#000' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderHome;
