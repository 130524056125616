export const SliderWorkspaceIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M2.5 12.5L8 14.5L7 18L8 21M17 20.5L16.5 18L14 17V13.5L17 12.5L21.5 13M19 5.5L18.5 7L15 7.5V10.5L17.5 9.5H19.5L21.5 10.5M2.5 10.5L5 8.5L7.5 8L9.5 5L8.5 3"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
