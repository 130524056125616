import { HeartIcon } from "../../img";

export const FavoritesCard = () => {
    return (
        <div className='layout-favorites'>
            <div className='layout-favorites__left'>
                <HeartIcon/>

                <h1>Избранное</h1>
            </div>

            <div className='layout-favorites__count'>
                12
            </div>
        </div>
    )
}