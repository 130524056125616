import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'

import "bootstrap-css";
import 'izitoast/dist/css/iziToast.min.css';
import './scss/style.scss';
import App from './App';
import { store } from "./redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ruRU } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
      <LocalizationProvider
          localeText={{ start: 'Check-in', end: 'Check-out' }}
          dateAdapter={AdapterDayjs}
      >
          <App />
      </LocalizationProvider>
  </Provider>
);
