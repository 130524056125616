export const successApiStatusCodes: number[] = [0]

export const successRequestStatusCodes: number[] = [200, 201, 202, 203, 204]

export const skipApiStatusCodes: number[] = []

export const staffCenterNotCodes: number[] = [406]

export const autoLogoutApiStatusCodes: number[] = [3001, 4001]

export const refreshTokenApiStatusCodes: number[] = [3004, 3011]
