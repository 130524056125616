import { useWindowSize } from "../../hooks";

export default function MobileImageAuth() {
    const [width] = useWindowSize()

    if (!width) return null

    return (
        <>
            {width <= 768 && (
                <div className="auth__content_left_block_mobile-swiper">
                    <img
                        src="/images/auth/banner.png"
                        alt="banner"
                        width={'200'}
                        height={'183'}
                        // layout="intrinsic"
                    />
                </div>
            )}
        </>
    )
}
