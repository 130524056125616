interface IRouteLinks {
    route: string,
    title: string
}
export const routeLinks: Record<string, IRouteLinks> = {
    main: {
        route: '/',
        title: 'Главная',
    },
    profile: {
        route: '/profile',
        title: 'Моя страница'
    },
    colleagues: {
        route: '/profile/colleagues',
        title: 'Мои сотрудники'
    },
    birthdays: {
        route: '/birthdays',
        title: 'Дни рождения'
    },
    employees: {
        route: '/colleagues',
        title: 'Новые сотрудники',
    },
    calendar: {
        route: '/calendar',
        title: 'Календарь',
    },
    vacation: {
        route: '/calendar/vacation',
        title: 'Отпуска'
    },
    medical: {
        route: '/calendar/medical',
        title: 'Больничный'
    },
    business: {
        route: '/calendar/business',
        title: 'Командировки'
    },
}