import React, { FC } from 'react'
import { IIconCustomSizeProps } from "../types"

export const PlusIcon: FC<IIconCustomSizeProps> = ({
    color = '#fff',
    width = 24,
    height = 25,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22.796C17.4492 22.796 21.9609 18.2745 21.9609 12.8351C21.9609 7.38583 17.4395 2.87411 11.9902 2.87411C6.55078 2.87411 2.03906 7.38583 2.03906 12.8351C2.03906 18.2745 6.56055 22.796 12 22.796ZM12 21.1358C7.39062 21.1358 3.70898 17.4444 3.70898 12.8351C3.70898 8.22568 7.38086 4.53427 11.9902 4.53427C16.5996 4.53427 20.291 8.22568 20.3008 12.8351C20.3105 17.4444 16.6094 21.1358 12 21.1358ZM11.9707 17.2198C12.498 17.2198 12.8008 16.8683 12.8008 16.2921V13.6554H15.6133C16.1602 13.6554 16.541 13.3722 16.541 12.8546C16.541 12.3272 16.1797 12.0245 15.6133 12.0245H12.8008V9.20224C12.8008 8.63583 12.498 8.27451 11.9707 8.27451C11.4531 8.27451 11.1699 8.65536 11.1699 9.20224V12.0245H8.36719C7.80078 12.0245 7.42969 12.3272 7.42969 12.8546C7.42969 13.3722 7.82031 13.6554 8.36719 13.6554H11.1699V16.2921C11.1699 16.8487 11.4531 17.2198 11.9707 17.2198Z"
                fill={color}
            />
        </svg>
    )
}
