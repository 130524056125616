import { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import getLineHeight from "./GetLineHeight";

export default function TextOverflow({ title = '', lines = 1, styles = {} }) {
    const targetRef: any = useRef();

    useEffect(() => {
        const target = targetRef.current;

        const lineHeight: any = getLineHeight(target);

        // target.style.maxHeight = `${lines * lineHeight}px`;

        let timeoutId: any;

        const handleResize = () => {
            if (lines !== 1) return;

            if (timeoutId) clearTimeout(timeoutId);

            target.style.height = `${lineHeight}px`;
            target.style.wordBreak = 'break-all';
            target.style.whiteSpace = 'normal';

            timeoutId = setTimeout(() => {
                // clientWidth rounds its value
                target.style.wordBreak = 'normal';
                target.style.whiteSpace = 'nowrap';
                target.style.height = 'auto';
            }, 50);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const style: CSSProperties = {
        overflow: 'hidden',
        ...styles,
    };

    if (lines === 1) {
        style.textOverflow = 'ellipsis';
        style.whiteSpace = 'nowrap';
    } else {
        style.display = '-webkit-box';
        style.WebkitBoxOrient = 'vertical';
        style.overflowWrap = 'anywhere';
        style.WebkitLineClamp = lines;
    }

    return (
        <div ref={targetRef} style={style}>
            {title}
        </div>
    );
}
