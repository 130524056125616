export const CheckboxIcon = ({
    color = '#007aff',
    width = 10,
    height = 8,
    clipStyle = '',
    style = {},
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 4.6L3.28571 7L9 1"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={clipStyle}
            style={style}
        />
    </svg>
)
