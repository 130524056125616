import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../redux";

interface SkeletonData {
    isShort?: boolean;
}

export const Skeleton = (
    {
        isShort
    }: SkeletonData
) => {
    const vars = useSelector(getProfileVarsData);

    return (
        <ContentLoader
            title=""
            height="64"
            backgroundColor="#E3E3E3"
            foregroundColor="#F9F9F9"
            gradientRatio={1}
            style={{
                width: "100%",
                height: "64px",
                backgroundColor: vars.colors.fourthColor,
                padding: isShort ? "" : "10px 15px"
            }}
        >
            <rect
                rx="50%"
                ry="50%"
                width="40"
                height="40"
            />

            <rect
                x="calc(40px + 14px)"
                y="5"
                rx="2"
                ry="2"
                width={isShort ? "30%" : "60%"}
                height="10"
            />

            <rect
                x="calc(40px + 14px)"
                y="calc(5px + 10px + 12px)"
                rx="2"
                ry="2"
                width={isShort ? "20%" : "40%"}
                height="8"
            />
        </ContentLoader>
    );
};
