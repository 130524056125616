export const SliderPracticesIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.49989 22C8.71059 21.9998 9.8874 21.6002 10.8478 20.863C11.8082 20.1258 12.4986 19.0924 12.8118 17.9229C13.125 16.7534 13.0436 15.5133 12.5802 14.3948C12.1167 13.2763 11.2972 12.3419 10.2487 11.7367C9.20011 11.1314 7.98115 10.889 6.78082 11.0471C5.58048 11.2052 4.46585 11.7549 3.60979 12.6111C2.75373 13.4672 2.20408 14.5819 2.04607 15.7822C1.88807 16.9826 2.13054 18.2015 2.73589 19.25L2.27489 21.725L4.74989 21.265C5.5857 21.7482 6.53445 22.0018 7.49989 22Z"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M15.282 17.8979C16.2395 17.7438 17.1607 17.4158 18 16.9299L21.6 17.5999L20.93 13.9999C21.5183 12.9794 21.8739 11.8416 21.9716 10.6677C22.0692 9.49381 21.9064 8.31285 21.4948 7.20918C21.0832 6.1055 20.4329 5.10633 19.5905 4.28305C18.748 3.45977 17.7341 2.83268 16.6213 2.44659C15.5084 2.06049 14.324 1.92492 13.1527 2.04954C11.9814 2.17416 10.852 2.55591 9.84531 3.16752C8.8386 3.77913 7.97938 4.60551 7.32901 5.58762C6.67864 6.56974 6.25318 7.68335 6.08301 8.84893"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
