import { FC } from "react";

interface ILinkCard {
    image: string;
    title: string;
}

interface IFeedUsefulLinksCard {
    data: ILinkCard
}
export const FeedUsefulLinksCard: FC<IFeedUsefulLinksCard> = ({
    data
}) => {
    const {image, title} = data

    const takeFirstLetter = title && title.slice(0, 1);

    return (
        <a>
            <div className='feed-links-card'>
                <div className='feed-links-card__imageBox'>
                    {image ? (
                        <img
                            className={`linksImage`}
                            src={image}
                            style={{
                                height: '2.5rem',
                                width: '2.5rem',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <div>{takeFirstLetter}</div>
                    )}
                </div>
                <div className='feed-links-card__title'>{title}</div>
            </div>
        </a>
    )
}