import { useState } from "react";
export const ContestCard = () => {
    const [data, setData] = useState([
        {
            id: 1,
            title: 'Маршрут одного дня',
            checkbox: ''
        },
        {
            id: 2,
            title: 'Выходные с ночёвкой',
            checkbox: ''
        },
        {
            id: 3,
            title: 'Праздники (несколько дней)',
            checkbox: ''
        }
    ])

    return (
        <div className='layout-contest'>
            <div className='layout-contest__header'>
                <img src="/testImages/contest/contest-1.png" alt="" />

                <h1>Какой формат поездки вам интереснее?</h1>
            </div>

            {/*<div className='layout-contest__content'>*/}
            {/*    {data.map((item, idx) => (*/}
            {/*        <div className='layout-contest__block' key={idx}>*/}
            {/*            <input type="checkbox" />*/}

            {/*            <h3>{item.title}</h3>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <button className='layout-contest__button'>Пройти опрос</button>
        </div>
    )
}