import React, { FC } from "react";
import dayjs from "dayjs";

export const CalendarContent: FC = () => {
  const currentDay = new Date().getDate()

  const getWeek = () => {
    const today = dayjs();
    const startOfWeek = today.startOf('week').add(1, 'day');
    const endOfWeek = today.endOf('week').add(1, 'day');
    const week = [];
    const weekDays = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

    let currentDay = startOfWeek;
    while (currentDay <= endOfWeek) {
      const dayOfWeekIndex = (currentDay.day() + 6) % 7;
      const dayOfWeek = weekDays[dayOfWeekIndex ];
      week.push({ day: currentDay.date(), week: dayOfWeek });
      currentDay = currentDay.add(1, 'day');
    }

    return week;
  };

  return (
    <div className='layout-calendar'>
      {getWeek().map((day, idx) => {
        const isActive: boolean = currentDay === day.day

        return (
          <div key={idx} className='layout-calendar__date'>
            <div style={{
              color: isActive ? '#fff' : '#000',
              backgroundColor: isActive ? 'rgba(90, 0, 254, 1)' : '#ffffff'
            }} className='layout-calendar__dateDay'>
              <p>{day.day}</p>
            </div>

            <p style={{ opacity: !isActive ? '0.5' : 'unset', fontWeight: isActive ? 600 : ' '}} className='layout-calendar__dateWeek'>
              {day.week}
            </p>
          </div>
        )
      })}
    </div>
  )
}
