export const SliderProfileIcon = () =>  {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.8569 2C7.33393 2 2.85693 6.477 2.85693 12C2.85693 17.523 7.33393 22 12.8569 22C18.3799 22 22.8569 17.523 22.8569 12C22.8569 6.477 18.3799 2 12.8569 2Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M5.12793 18.346C5.12793 18.346 7.35693 15.5 12.8569 15.5C18.3569 15.5 20.5869 18.346 20.5869 18.346M12.8569 12C13.6526 12 14.4156 11.6839 14.9783 11.1213C15.5409 10.5587 15.8569 9.79565 15.8569 9C15.8569 8.20435 15.5409 7.44129 14.9783 6.87868C14.4156 6.31607 13.6526 6 12.8569 6C12.0613 6 11.2982 6.31607 10.7356 6.87868C10.173 7.44129 9.85693 8.20435 9.85693 9C9.85693 9.79565 10.173 10.5587 10.7356 11.1213C11.2982 11.6839 12.0613 12 12.8569 12Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
