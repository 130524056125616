import { ArrowRightIcon } from "../../../img";
import { useState } from "react";
import { FeedEventsCard } from "./FeedEventsCard";

export const FeedEventsContent = () => {
    const [eventsContent, setEventsContent] = useState([
        {
            id: 1,
            title: 'Волейбольный турнир: Полуфинал',
            date: '10 августа',
            type: 'Спортивные мероприятия',
            image: '/testImages/events/events-1.png'
        },
        {
            id: 1,
            title: 'Волейбольный турнир: Полуфинал',
            date: '10 августа',
            type: 'Спортивные мероприятия',
            image: '/testImages/events/events-1.png'
        },
        {
            id: 1,
            title: 'Волейбольный турнир: Полуфинал',
            date: '10 августа',
            type: 'Спортивные мероприятия',
            image: '/testImages/events/events-1.png'
        },
        {
            id: 1,
            title: 'Волейбольный турнир: Полуфинал',
            date: '10 августа',
            type: 'Спортивные мероприятия',
            image: '/testImages/events/events-1.png'
        }
    ])
    return (
        <div className='feed-events'>
            <div className='feed-controls feed-news__controls' >
                {/* <div className={allViewsClass}>{allViews}</div> */}
                <h3>Мероприятия</h3>

                <div>
                    <h4>Все мероприятия</h4>
                    <ArrowRightIcon color={'#000'}/>
                </div>
            </div>

            <div className='feed-events__content'>
                {eventsContent.map((item, idx) => (
                    <FeedEventsCard data={item} key={idx}/>
                ))}
            </div>
        </div>
    )
}