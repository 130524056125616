import { FC, ReactNode, useEffect } from 'react'
import Modal from 'react-modal'
import { CloseIcon } from '../../../img'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}

interface Dialog {
    children: ReactNode
    isOpen: boolean
    setIsOpen?: (is: boolean) => void
    closeModal?: () => void
    styles?: any
    label?: string | ReactNode
    widthProps?: number
    isShowCloseBtn?: boolean
    closeIcon?: ReactNode
    classNameHeader?: string
    classNameModal?: string
    isCloseIconOutside?: boolean
}

const DialogContainer: FC<Dialog> = ({
    children,
    isOpen,
    setIsOpen,
    closeModal,
    styles,
    label = '',
    widthProps = 500,
    isShowCloseBtn = true,
    closeIcon = <CloseIcon color={'black'} />,
    classNameHeader = '',
    classNameModal = '',
    isCloseIconOutside,
}) => {
    let subtitle: any

    function afterOpenModal() {
        if (subtitle) subtitle.style.color = 'red'
    }

    function closeModalLocal() {
        setIsOpen && setIsOpen(false)

        closeModal && closeModal()
    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <>
            <Modal
                closeTimeoutMS={0}
                isOpen={isOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalLocal}
                style={{
                    ...customStyles,
                    ...styles,
                    content: {
                        maxWidth: `${widthProps / 16}rem`,
                    },
                }}
                ariaHideApp={false}
                className={`modal__block ${classNameModal} ${isCloseIconOutside ? 'modal__close-outside' : ''}`}
                overlayClassName={'modal__container'}
            >
                {(label || isShowCloseBtn) && (
                    <div className={`modal__block-header ${classNameHeader}`}>
                        {label && <p>{label}</p>}

                        {isShowCloseBtn && (
                            <button
                                onClick={closeModalLocal}
                                className={'modal__block-close'}
                            >
                                {isCloseIconOutside ? (
                                    <CloseIcon color={'white'} opacity={'1'} />
                                ) : (
                                    closeIcon
                                )}
                            </button>
                        )}
                    </div>
                )}

                {children}
            </Modal>
        </>
    )
}

export default DialogContainer
