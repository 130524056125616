import { FC, useState } from "react";
import DialogContainer from "../ui/dialog/DialogContainer";
import { CloseIcon, EmailIcon, PenIcon, PhoneIcon, SearchIcon } from "../../img";

interface IProfileDepartmentBlock {
    id?: number,
    name: string;
    image: string;
    position: string;
    icon: string;
    isMessage?: boolean;
    department?: string;
}

interface IVacationCard {
    data: IProfileDepartmentBlock[]
}

export const MobileVacationCard: FC<IVacationCard> = ({
    data
}) => {
    const [ isDialog, setIsDialog ] = useState(false)
    const [ isReplacerDialog, setIsReplacerDialog ] = useState(false)

    const [ selectedReplacer, setSelectedReplacer ] = useState<number>(-1)
    const [ replacer, setReplacer ] = useState<any>(null)

    const handleSelectReplacer = () => {
        if (selectedReplacer === -1) return

        setIsReplacerDialog(false)
        setReplacer(data[selectedReplacer])
    }

    return (
        <div className='layout-vacation-mobile' onClick={ () => !isDialog && setIsDialog(true) }>
            <h1 className='layout-vacation-mobile__title'>До отпуска</h1>

            <h2 className='layout-vacation-mobile__day'>8 дней</h2>

            <DialogContainer isShowCloseBtn={false} isOpen={ isDialog } closeModal={() => isDialog && setIsDialog(false)}>
                <div className='layout-vacation-mobile__dialogHeader'>
                    <h1 className='layout-vacation-mobile__dialogTitle'>Отпуск</h1>

                    <div onClick={() => isDialog && setIsDialog(false)}>
                        <CloseIcon color={'black'} opacity={'1'} />
                    </div>
                </div>

                <div className='layout-vacation__header'>
                    <h5>До отпуска</h5>

                    <h1>8 дней</h1>
                </div>

                <div className='layout-vacation__dateWrapper'>
                    <h3>Ближайший отпуск</h3>

                    <div className='layout-vacation__dateContent'>
                        <p>21.02.2023</p>
                        <p>21.02.2023</p>
                    </div>
                </div>

                <div className='layout-vacation__body'>
                    <p>Количество неотгуленных дней</p>
                    <h3>14</h3>
                </div>

                { replacer && (
                    <div className='layout-vacation-replacer'>
                        <p className='layout-vacation-replacer__title'>Замещающий</p>

                        <div className='layout-vacation-replacer__content'>
                            <div className='layout-vacation-replacer__content__top'>
                                <img src={ replacer?.image } alt="" />

                                <div onClick={() => setIsReplacerDialog(true) }>
                                    <PenIcon />
                                </div>
                            </div>

                            <div className='layout-vacation-replacer__content__desc'>
                                <h1>{ replacer?.name }</h1>

                                <div className='layout-vacation-replacer__content__contact'>
                                    <PhoneIcon />
                                    <p>+7 925 353-01-10</p>
                                </div>

                                <div className='layout-vacation-replacer__content__contact'>
                                    <EmailIcon />
                                    <p>ivanov@itelma.ru</p>
                                </div>
                            </div>

                        </div>
                    </div>
                ) }

                <div className='layout-vacation__buttons'>
                    { !replacer &&
                      <button onClick={ () => setIsReplacerDialog(true) }
                              className='layout-vacation__replaceButton'>Выбрать замещающего</button>
                    }
                    <button className='layout-vacation__calendarButton'>Календарь отпусков</button>
                </div>
            </DialogContainer>


            <DialogContainer isOpen={ isReplacerDialog } closeModal={ () => setIsReplacerDialog(false) }>
                <div className='layout-vacation-modal'>
                    <div className='layout-vacation-modal__header'>
                        <h1 className='layout-vacation-modal__title'>Выбор замещающего</h1>

                        <div className='layout-vacation-modal__search'>
                            <SearchIcon color='#000' />

                            <input type="search" placeholder='Поиск' />
                        </div>
                    </div>

                    <div className='layout-vacation-modal__content'>
                        { data.map((
                            item,
                            idx
                        ) => (
                            <div key={ idx } className='layout-vacation-modal__block'>
                                <input
                                    type="checkbox"
                                    checked={ idx === selectedReplacer }
                                    onClick={ () => idx === selectedReplacer ? setSelectedReplacer(-1) : setSelectedReplacer(idx) }
                                />

                                <div className='layout-vacation-modal__block__right'>
                                    <img src={ item.image } alt="" />

                                    <div className='layout-vacation-modal__block__desc'>
                                        <h1>{ item.name }</h1>

                                        <p className='layout-vacation-modal__block__desc__position'>{ item.position }</p>

                                        <p className='layout-vacation-modal__block__desc__department'>{ item.department }</p>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>

                    <div className='layout-vacation-modal__linearButton'>
                        <button style={ {opacity: selectedReplacer === -1 ? '0.5' : 'unset'} }
                                className='layout-vacation-modal__button' onClick={ handleSelectReplacer }>
                            Выбрать
                        </button>
                    </div>
                </div>
            </DialogContainer>
        </div>
    )
}