import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ProfileEnvelopeIcon:FC<IBaseIconProps> = ({
    color = '#00A7B5',
    size = 24,
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 4H4C2.895 4 2 4.895 2 6V18C2 19.105 2.895 20 4 20H20C21.105 20 22 19.105 22 18V6C22 4.895 21.105 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                fill={color} />
        </svg>
    )
}
