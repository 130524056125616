

export default function NewsDateIcon({ fill = '#000', size = '20', style = {} }) {
    return (
        <svg
            className={`dateImage`}
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...style }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3333 1.66663C5.74083 1.66663 2 5.40746 2 9.99996C2 14.5925 5.74083 18.3333 10.3333 18.3333C14.9258 18.3333 18.6667 14.5925 18.6667 9.99996C18.6667 5.40746 14.9258 1.66663 10.3333 1.66663ZM10.3333 3.33329C14.0251 3.33329 17 6.30819 17 9.99996C17 13.6917 14.0251 16.6666 10.3333 16.6666C6.64156 16.6666 3.66667 13.6917 3.66667 9.99996C3.66667 6.30819 6.64156 3.33329 10.3333 3.33329ZM9.5 4.99996V10.345L13.0775 13.9225L14.2559 12.7441L11.1667 9.65491V4.99996H9.5Z"
                fill={fill}
            />
        </svg>
    );
}
