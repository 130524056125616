import {
    ArrowExitIcon,
    ArrowRightIcon,
    CalendarIcon,
    EmailIcon,
    PhoneIcon,
    ReplacingIcon,
    SearchIcon
} from "../../../img";
import { useNavigate } from "react-router-dom";
import MainLayoutContent from "../../../layouts/MainLayoutContent";
import { VacationLeftContent } from "../../calendar/vacation/VacationLeftContent";
import { useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";

interface IColleagues {
    id?: number,
    image?: string,
    name?: string,
    position?: string,
    department?: string,
    date?: string,
    allDays?: number,
    workDay?: number,
    replacer?: IReplacer
}

interface IReplacer {
    name: string,
    image: string,
    position: string,
}

export const ProfileColleagues = () => {
    const navigate = useNavigate();
    const isMobile = window.screen.width < 1020;

    const [ dialog, setDialog ] = useState('closed')
    const [ activePerson, setActivePerson ] = useState<IColleagues>()

    const [ vacationList, setVacationList ] = useState<IColleagues[]>([
        {
            id: 1,
            image: "/testImages/people/Image-1.png",
            name: "Краюшкин Александр Витальевич",
            position: "Руководитель",
            department: 'Департамент испытания и сертификации',
            date: 'с 25.08.2024 по 30.08.2024',
            allDays: 5,
            workDay: 3
        },
        {
            id: 2,
            image: "/testImages/people/Image-2.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            department: 'Департамент испытания и сертификации',
            date: 'с 25.08.2024 по 30.08.2024',
            allDays: 5,
            workDay: 3
        },
        {
            id: 3,
            image: "/testImages/people/Image-3.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            department: 'Департамент испытания и сертификации',
            date: 'с 25.08.2024 по 30.08.2024',
            allDays: 5,
            workDay: 3
        },
    ]);

    const [ ambulanceData, setAmbulanceData ] = useState<IColleagues[]>([
        {
            id: 5,
            image: "/testImages/people/Image-5.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            department: 'Департамент испытания и сертификации',
            date: 'с 25.08.2024 по 30.08.2024',
            allDays: 5,
            workDay: 3
        },
        {
            id: 4,
            image: "/testImages/people/Image-4.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            department: 'Департамент испытания и сертификации',
            date: 'с 25.08.2024 по 30.08.2024',
            allDays: 5,
            workDay: 3
        },
    ])

    const [ replacingContent, setReplacingContent ] = useState<IColleagues[]>([
        {
            id: 1,
            image: '/testImages/people/Image-4.png',
            name: 'Воробьёв Илья Романович',
            position: 'Руководитель отдела',
            date: 'с 25.08.2024 по 30.08.2024',
            workDay: 3,
            replacer: {
                name: 'Смирнова Виалета Михайловна',
                image: '/testImages/people/Image-3.png',
                position: 'Департамент испытания и сертификации'
            }
        },
        {
            id: 2,
            image: '/testImages/people/Image-1.png',
            name: 'Воробьёв Илья Романович',
            position: 'Руководитель отдела',
            date: 'с 25.08.2024 по 30.08.2024',
            workDay: 3,
            replacer: {
                name: 'Смирнова Виалета Михайловна',
                image: '/testImages/people/Image-2.png',
                position: 'Департамент испытания и сертификации'
            }
        }
    ])

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    const handleOpenDialog = (
        item: IColleagues,
        type: string
    ) => {
        setActivePerson(item)
        setDialog(type)
    }

    return (
        <div className='profile-colleagues'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Мои сотрудники</h1>
                    </div>
                ) }

                <div className='profile-colleagues-header__calendar'>
                    <CalendarIcon/>

                    <p className='profile-colleagues-header__calendar__title'>Календарь</p>

                    <span className='notification-circle notification-circle__red'>3</span>
                </div>
            </div>

            <MainLayoutContent contentGap={ 40 } leftComponents={
                <div className='vacation__content__left'>
                    <VacationLeftContent />
                </div>
            }>
                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Отпуска </h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { vacationList.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-block' key={ idx }>
                                <div className='profile-colleagues-block__left'>
                                    <img src={ item.image } alt="" className='profile-colleagues-block__image' />

                                    <div className='profile-colleagues-block__desc'>
                                        <h1 className='profile-colleagues-block__name'>{ item.name }</h1>
                                        <p className='profile-colleagues-block__position'>{ item.position }</p>
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__middle'>
                                    <p className='profile-colleagues-block__placeholder'>Даты отпуска</p>

                                    <div className='profile-colleagues-block__date'>
                                        { item.date }
                                    </div>
                                </div>

                                <div
                                    onClick={ () => handleOpenDialog(item, 'vacation') }
                                    className='profile-colleagues-block__button'
                                >
                                    Согласовать
                                </div>
                            </div>
                        )) }
                    </div>
                </div>

                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Работа в больничный </h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { ambulanceData.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-block' key={ idx }>
                                <div className='profile-colleagues-block__left'>
                                    <img src={ item.image } alt="" className='profile-colleagues-block__image' />

                                    <div className='profile-colleagues-block__desc'>
                                        <h1 className='profile-colleagues-block__name'>{ item.name }</h1>
                                        <p className='profile-colleagues-block__position'>{ item.position }</p>
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__middle'>
                                    <p className='profile-colleagues-block__workDays'>{ item.allDays } рабочих дня</p>

                                    <div className='profile-colleagues-block__ambulaneDate'>
                                        { item.date }
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__button'
                                     onClick={ () => handleOpenDialog(item, 'vacation') }
                                >
                                    Согласовать
                                </div>
                            </div>
                        )) }
                    </div>
                </div>

                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Замещающий сотрудник </h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { replacingContent.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-replacing' key={ idx }>
                                <div className='profile-colleagues-replacing__content'>
                                    <div className='profile-colleagues-block__left'>
                                        <img src={ item.image } alt="" className='profile-colleagues-block__image' />

                                        <div className='profile-colleagues-block__desc'>
                                            <h1 className='profile-colleagues-block__name'>{ item.name }</h1>
                                            <p className='profile-colleagues-block__position'>{ item.position }</p>
                                        </div>
                                    </div>

                                    <ReplacingIcon />

                                    { item.replacer && (
                                        <div className='profile-colleagues-block__left'>
                                            <img src={ item.replacer.image } alt=""
                                                 className='profile-colleagues-block__image' />

                                            <div className='profile-colleagues-block__desc'>
                                                <h1 className='profile-colleagues-block__name'>{ item.replacer.name }</h1>
                                                <p className='profile-colleagues-block__position'>{ item.replacer.position }</p>
                                            </div>
                                        </div>
                                    ) }
                                </div>

                                <div className='profile-colleagues-block__button'
                                     onClick={ () => handleOpenDialog(item, 'replacing') }>
                                    Согласовать
                                </div>
                            </div>
                        )) }
                    </div>
                </div>


                <DialogContainer isOpen={ dialog === 'replacing' || dialog === 'vacation' }
                                 closeModal={ () => setDialog('closed') }>

                    { activePerson &&
                        (
                            <>
                                { dialog === 'replacing' ? (
                                    <>
                                        <div className='layout-vacation-modal__header'>
                                            { dialog === 'replacing' ? (
                                                <h1 className='layout-vacation-modal__title'>Согласование
                                                    замещающего</h1>
                                            ) : null
                                            }
                                        </div>

                                        <div className='profile-colleagues-modal__content'>
                                            <div className='profile-colleagues-modal__content__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Замещаемый</p>

                                                <div className='profile-colleagues-block__left'>
                                                    <img src={ activePerson.image } alt=""
                                                         className='profile-colleagues-block__image' />

                                                    <div className='profile-colleagues-block__desc'>
                                                        <h1 className='profile-colleagues-block__name'>{ activePerson.name }</h1>
                                                        <p className='profile-colleagues-block__position'>{ activePerson.position }</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='profile-colleagues-modal__content__block__icon'>
                                                <ReplacingIcon />
                                            </div>

                                            { activePerson.replacer && (
                                                <div className='profile-colleagues-modal__content__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Замещающий</p>

                                                    <div className='profile-colleagues-block__left'>
                                                        <img src={ activePerson.replacer.image } alt=""
                                                             className='profile-colleagues-block__image' />

                                                        <div className='profile-colleagues-block__desc'>
                                                            <h1 className='profile-colleagues-block__name'>{ activePerson.replacer.name }</h1>
                                                            <p className='profile-colleagues-block__position'>{ activePerson.replacer.position }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) }
                                        </div>

                                        <div className='profile-colleagues-modal__information'>
                                            <h1>Информация</h1>

                                            <div className='profile-colleagues-modal__information__dates'>
                                                <p className='profile-colleagues-modal__placeholder'>Даты</p>

                                                <h1>{ activePerson.date }</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__information__dates'>
                                                <p className='profile-colleagues-modal__placeholder'>Рабочих дней</p>

                                                <h1>{ activePerson.workDay } дня</h1>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='employees-modal__top'>
                                            <img src={ activePerson?.image } alt="" />

                                            <h1>
                                                { activePerson?.name }
                                            </h1>
                                        </div>


                                        <div className='profile-colleagues-modal__vacationContent'>
                                            <div className='profile-colleagues-modal__vacationContent__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Департамент</p>

                                                <h1 className='profile-colleagues-modal__vacationContent__block__title'>{ activePerson.department } дня</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__vacationContent__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Должность</p>

                                                <h1 className='profile-colleagues-modal__vacationContent__block__title'>{ activePerson.position } дня</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__vacationContent__information'>
                                                <h1 className='profile-colleagues-modal__vacationContent__information__title'>
                                                    Согласование работы в больничный
                                                </h1>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Даты по больничному листу</p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        {activePerson.date}
                                                    </h1>
                                                </div>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Даты по больничному листу</p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        {activePerson.allDays} дней
                                                    </h1>
                                                </div>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Из них рабочих дней</p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        {activePerson.workDay} дня
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) }

                                <div className='profile-colleagues-modal__buttons'>
                                    <button className='profile-colleagues-modal__buttons__accept'>Согласовать</button>
                                    <button className='profile-colleagues-modal__buttons__reject'>Отклонить</button>
                                </div>
                            </>
                        )
                    }

                </DialogContainer>
            </MainLayoutContent>
        </div>
    )
}