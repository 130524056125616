import { FC } from "react";
import { EventsTypeIcon } from "../../../img";

interface EventsCard {
    id: number,
    title: string,
    date: string,
    image: string,
    type: string,
}

interface IFeedEventsCard {
    data: EventsCard
}
export const FeedEventsCard: FC<IFeedEventsCard> = ({
    data
}) => {
    const {
        image,
        type,
        title,
        date,
    } = data

    return (
        <div style={{backgroundImage: `url(${image})`}} className='feed-events-card'>
            <div className='feed-events-card__backgroundLinear'></div>

            <div className='feed-events-card__content'>
                <div className='feed-events-card__type'>
                    <EventsTypeIcon/>

                    <h1>{type}</h1>
                </div>

                <div className='feed-events-card__desc'>
                    <h1>{title}</h1>

                    <div className='feed-events-card__desc__date'>
                        {date}
                    </div>
                </div>
            </div>
        </div>
    )
}