import { FC, useState } from "react";
import { ArrowRightIcon } from "../../../img";
import { FeedGroupsSlider } from "./FeedGroupsSlider";

export const FeedGroupsContent: FC = ( ) => {
    const isMobile = window.screen.width < 1020
    const [groupsContent, setGroupsContent] = useState([
        {
            id: 0,
            type: 'sport',
            typeTitle: 'Спорт',
            title: 'Старт 1-го модуля программы Благополучие с ВСК | Здоровое питание',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/ZDJJRMdYDZ1713965433.png',
        },
        {
            id: 1,
            type: 'sport',
            typeTitle: 'Спорт',
            title: 'Старт 1-го модуля программы Благополучие с ВСК | Здоровое питание',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/hWHfGW4jaa1685714875.png',
        },
        {
            id: 2,
            type: 'wellbeing',
            typeTitle: 'Благополучие с ВСК',
            title: 'Названы три способа, как избавиться от осенней хандры',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/mXkrp06pkd.png',
        },
        {
            id: 3,
            type: 'wellbeing',
            typeTitle: 'Благополучие с ВСК',
            title: 'Старт 1-го модуля программы Благополучие с ВСК | Здоровое питание',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/cEhBMwLZzW.png',
        },
        {
            id: 4,
            type: 'wellbeing',
            typeTitle: 'Благополучие с ВСК',
            title: 'Старт 1-го модуля программы Благополучие с ВСК | Здоровое питание',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/cEhBMwLZzW.png',
        },
        {
            id: 5,
            type: 'practices',
            typeTitle: 'Лучшие практики',
            title: 'Лучшие практики ЦКС. ПДЗ (просроченная дебиторская задолженность)',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/cEhBMwLZzW.png',
        },
        {
            id: 6,
            type: 'practices',
            typeTitle: 'Лучшие практики',
            title: 'Старт 1-го модуля программы Благополучие с ВСК | Здоровое питание',
            typeIcon: '',
            image: 'https://api.svoi.club/images/news/cEhBMwLZzW.png',
        },
    ])

    const getSportGroups = groupsContent.filter((item) => item.type === 'sport')
    const getWellBeingGroups = groupsContent.filter((item) => item.type === 'wellbeing')
    const getPracticesGroups = groupsContent.filter((item) => item.type === 'practices')

    return (
        <div className='feed-groups'>
            <div className='feed-controls feed-groups__controls'>
                {isMobile ? (
                    <h3 >Группы</h3>
                ) : (
                    <h3 >События в группах</h3>
                )}


                <div >
                    <h4>Все группы</h4>
                    <ArrowRightIcon color='#000'/>
                </div>
            </div>

            <div className='feed-groups__container'>
                {getSportGroups && (
                    <FeedGroupsSlider
                        slidersNumber={getSportGroups.length}
                        // slidersNumber={filteredBanners.length + partners?.length}
                        slides={[...getSportGroups]}
                    />
                )}

                <FeedGroupsSlider
                    slidersNumber={getWellBeingGroups.length}
                    // slidersNumber={filteredBanners.length + partners?.length}
                    slides={[...getWellBeingGroups]}
                />

                <FeedGroupsSlider
                    slidersNumber={getPracticesGroups.length}
                    // slidersNumber={filteredBanners.length + partners?.length}
                    slides={[...getPracticesGroups]}
                />
            </div>
        </div>
    )
}