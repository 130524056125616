import { MaskProps } from 'react-mask-field/dist/types'
import { FieldMaskProps } from "../types"

export const getOnlyNumbers = (value: string): string =>
    value.replace(/[^0-9]/g, '')

export const getMaskedValue = (
    value?: string,
    mask?: string,
    replacers?: string
) => {
    if (!value || !mask || !replacers) return ''

    const maskArray: string[] = mask.split('')
    const valueArray: string[] = value.split('')

    for (let i = 0; i < maskArray.length; i++) {
        if (replacers.indexOf(maskArray[i]) > -1)
            maskArray[i] = valueArray.shift() || ''
    }

    return maskArray.join('').trim()
}

export const createDefaultFieldMask = (
    mask: MaskProps,
    getUnmaskedValue: FieldMaskProps['getUnmaskedValue']
): FieldMaskProps => ({
    mask,
    getUnmaskedValue,
    getMaskedValue(value) {
        return getFieldMaskedValue(value, this.mask)
    },
})

export const createNumberFieldMask = (mask: MaskProps): FieldMaskProps =>
    createDefaultFieldMask(mask, getOnlyNumbers)

export const getFieldMaskedValue = (
    value: string | undefined,
    mask: MaskProps
): string =>
    getMaskedValue(
        value,
        mask.mask,
        Object.keys(mask.replacement as string).join('')
    )
