import React, { FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
// import 'swiper/swiper.scss'

const AuthSwiper: FC = () => {
    return (
        <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            spaceBetween={50}
            slidesPerView={1}
        >
            <SwiperSlide>
                <div className="auth__content_right_block">
                    <div className="auth__content_right_item">
                        <div className="auth__content_right_item-banner">
                            <img src="/images/auth/banner.png" alt="banner" />
                            {/*<Image*/}
                            {/*    src="/images/auth/banner.png"*/}
                            {/*    alt="banner"*/}
                            {/*    width={493}*/}
                            {/*    height={453}*/}
                            {/*    layout="intrinsic"*/}
                            {/*/>*/}
                        </div>

                        <div className="auth__content_right_item-text">
                            <h3>Планировать свое пространство удобно</h3>

                            <p>Планируй свое пространство</p>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="auth__content_right_block">
                    <div className="auth__content_right_item">
                        <div className="auth__content_right_item-banner">
							<img
								src="/images/auth/banner.png"
								alt="banner"
								width={493}
								height={453}
							/>
                        </div>

                        <div className="auth__content_right_item-text">
                            <h3>Планировать свое пространство удобно</h3>

                            <p>Планируй свое пространство</p>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="auth__content_right_block">
                    <div className="auth__content_right_item">
                        <div className="auth__content_right_item-banner">
                            <img
                                src="/images/auth/banner.png"
                                alt="banner"
                                width={493}
                                height={453}
                            />
                        </div>

                        <div className="auth__content_right_item-text">
                            <h3>Планировать свое пространство удобно</h3>

                            <p>Планируй свое пространство</p>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    )
}

export default AuthSwiper
