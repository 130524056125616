import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ProfileChatIcon: FC<IBaseIconProps> = ({
    color = '#00A7B5',
    size= 24,
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.486 2 2 6.038 2 11C2 16.259 7.04 20.508 13 19.953V23.093L14.54 22.105C15.672 21.379 21.347 17.477 21.943 11.955C21.98 11.641 22 11.322 22 11C22 6.038 17.514 2 12 2ZM9 12H7V10H9V12ZM13 12H11V10H13V12ZM17 12H15V10H17V12Z"
                fill={color} />
        </svg>
    )
}
