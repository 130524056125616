import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignInLayout from "../layouts/SignInLayout";
import { useSelector } from "react-redux";
import { getProfileData } from "../redux";
import Auth from "../containers/auth";
import Activate from "../containers/auth/activate";
import ProtectedRoute from "../components/ui/ProtectedRoute";
import NotFound from "../components/ui/NotFound";
import Register from "../containers/auth/register";
import {
    MainPage,
    ProfilePage,
    WorkspacePage,
    CalendarPage,
    ReferencePage,
    ServicePage,
    Contacts,
    VacationPage,
    BirthdaysPage,
    EmployeesPage,
    ProfileColleagues
} from "../containers";
import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import InnerLayout from "../layouts/InnerLayout";

const RoutesComponent = () => {
    const pathname = window.location.pathname
    const userData = useSelector(getProfileData);
    const isMobile = window.screen.width < 1020
    const [ isScrolled, setIsScrolled ] = useState(false)

    const redirectTo = (url: string): string => {
        if (window.location.pathname !== url) window.location.href = url;

        return url;
    };

    const isRouterMain = (): string => {
        if (!userData) return "/auth";

        if (userData?.role === 9) return redirectTo("/guest");

        return userData?.centerId === null ? "/non/game" : "/";
    };

    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setIsScrolled(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pathname]);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, [pathname]);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={<SignInLayout />}
                >
                    <Route path="/auth" element={<Auth />} />

                    <Route path="/verification" element={<Activate />} />
                    <Route path="/register" element={<Register />} />
                </Route>

                <Route
                    element={<MainLayout isScrolled={isScrolled}/>}
                >
                    <Route path="/" element={<ProtectedRoute />}>
                        {/*<Route index element={<Navigate to={isRouterMain()} replace/>}/>*/}

                        <Route index element={<MainPage isScrolled={isScrolled} />} />

                        <Route path='workspace' element={<WorkspacePage/>}/>

                        <Route path='calendar'>
                            <Route path='' element={<CalendarPage/>}/>
                        </Route>

                        <Route path='reference' element={<ReferencePage/>}/>

                        <Route path='contacts' element={<Contacts/>}/>

                        <Route path='service' element={<ServicePage/>}/>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout />}>
                    <Route path='calendar'>
                        <Route path='vacation' element={<VacationPage/>}/>
                        <Route path='medical' element={<VacationPage/>}/>
                        <Route path='business' element={<VacationPage/>}/>
                    </Route>

                    <Route path='employees' element={ <EmployeesPage /> } />

                    <Route path='profile' element={<ProfilePage/>}/>

                    <Route path="profile" >
                        <Route path='colleagues' element={<ProfileColleagues/>}/>
                    </Route>

                    <Route path='birthdays' element={<BirthdaysPage/>}></Route>
                </Route>


                <Route
                    path={"*"}
                    element={<NotFound />}
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
