import { FieldMaskProps } from "../types"
import { createNumberFieldMask } from './field-mask-utils'

export const phoneNumberFieldMask: FieldMaskProps = createNumberFieldMask({
    mask: '+_ ___ ___-__-__', // 9 999 999-99-99
    replacement: { _: /\d/ },
})

export const cardExpiryFieldMask: FieldMaskProps = createNumberFieldMask({
    mask: '__/__',
    replacement: { _: /\d/ },
})

export const formatTransferCardPan = (cardPan: string): string => {
    if (cardPan.indexOf('*') < 0)
        cardPan = `${cardPan.slice(0, 6)}******${cardPan.slice(12)}`

    return putSpacesAtInterval(cardPan, 4)
}

export const putSpacesAtInterval = (
    text: string,
    interval: number = 4
): string => {
    const textArray: string[] = text.split('')
    let formattedText: string = ''

    while (textArray.length)
        formattedText += ' ' + textArray.splice(0, interval).join('')

    return formattedText
}
