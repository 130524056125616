import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const MenuIcon: FC<IBaseIconProps> = ({
    color = 'white',
    size = 24,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 5V7H22V5H2ZM2 11V13H22V11H2ZM2 17V19H22V17H2Z"
                fill={color}
            />
        </svg>
    )
}
