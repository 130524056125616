export const vacationColors = [
    '#D7C3FF',
    '#D2F1B3',
    '#FDD3DA',
    '#96C0FE',
    '#FAE292',
    '#A0EEE9',
    '#C0C8D9',
    '#F6BCFF',
    '#E9C1A7',
    '#CDE4FF',
];

export const vacationHoverColors = [
    '#AE90EB',
    '#ACE277',
    '#F1A3B0',
    '#679FF0',
    '#EDC94F',
    '#5DDDD5',
    '#93A1BD',
    '#D98FE5',
    '#DE9D73',
    '#95BBE8',
];

export const vacationStatus: any = {
    '-1': 'Не согласовано',
    0: 'Отменён',
    1: 'На согласовании',
    2: 'Согласовано',
};
