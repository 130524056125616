const CryptoJS = require('crypto-js')

export default function decryptAes(
    data: any,
    {
        isDecryptError = false,
        setDecryptError = () => {},
        isPublic = false,
        secret = '',
    } = {}
) {
    const localKey = localStorage.getItem('secret') || ''

    if (!localKey) {
        setDecryptError()
        return null
    }

    if (isDecryptError) return null

    const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000')
    // const key = CryptoJS.enc.Latin1.parse('S-1-5-21-1606980848-606747145-1801674531-1711328'.slice(0, 32));
    const key = CryptoJS.enc.Latin1.parse(
        isPublic ? secret.slice(0, 32) : localKey.slice(0, 32)
    )

    try {
        const decrypted = CryptoJS.AES.decrypt(data.replace(/\\/g, ''), key, {
            iv,
        })
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8)

        if (!decryptedText) {
            setDecryptError()
        }

        return decryptedText || data
    } catch (e) {
        setDecryptError()
        return data
    }
}
