export const SliderWorkplaceIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.42871 22H17.4287" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path
                d="M2.42871 17V4C2.42871 3.46957 2.63942 2.96086 3.0145 2.58579C3.38957 2.21071 3.89828 2 4.42871 2H20.4287C20.9591 2 21.4679 2.21071 21.8429 2.58579C22.218 2.96086 22.4287 3.46957 22.4287 4V17C22.4287 17.5304 22.218 18.0391 21.8429 18.4142C21.4679 18.7893 20.9591 19 20.4287 19H4.42871C3.89828 19 3.38957 18.7893 3.0145 18.4142C2.63942 18.0391 2.42871 17.5304 2.42871 17Z"
                stroke="currentColor" strokeWidth="2" />
            <path d="M9.42871 10.5L11.4287 12.5L15.4287 8.5" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
