export const SliderWellbeingIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6667 6.28231C16.6666 6.88402 16.4286 7.4613 16.0047 7.88831C15.0287 8.87231 14.0817 9.89831 13.0687 10.8463C12.9556 10.9493 12.8073 11.005 12.6543 11.0019C12.5014 10.9987 12.3554 10.9369 12.2467 10.8293L9.3287 7.88931C8.90454 7.46203 8.6665 6.88437 8.6665 6.28231C8.6665 5.68024 8.90454 5.10259 9.3287 4.67531C9.54036 4.46209 9.79211 4.29286 10.0695 4.17738C10.3468 4.0619 10.6443 4.00245 10.9447 4.00245C11.2451 4.00245 11.5426 4.0619 11.8199 4.17738C12.0973 4.29286 12.3491 4.46209 12.5607 4.67531L12.6667 4.78231L12.7727 4.67531C13.0904 4.35417 13.4965 4.13484 13.9392 4.04523C14.382 3.95562 14.8414 3.99979 15.259 4.17212C15.6765 4.34444 16.0334 4.63713 16.2841 5.01291C16.5348 5.38869 16.668 5.83058 16.6667 6.28231Z"
                stroke="white" strokeWidth="2" strokeLinejoin="round" />
            <path
                d="M18.6667 20.0022L22.4907 16.1782C22.6033 16.0658 22.6666 15.9133 22.6667 15.7542V10.5022C22.6667 10.1044 22.5087 9.72284 22.2274 9.44154C21.9461 9.16023 21.5646 9.0022 21.1667 9.0022C20.7689 9.0022 20.3874 9.16023 20.1061 9.44154C19.8248 9.72284 19.6667 10.1044 19.6667 10.5022V15.0022"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M18.6667 16.0022L19.5247 15.1442C19.5698 15.0992 19.6056 15.0457 19.6299 14.9869C19.6543 14.928 19.6668 14.8649 19.6667 14.8012C19.6666 14.7113 19.6415 14.6232 19.5941 14.5467C19.5468 14.4702 19.4792 14.4084 19.3987 14.3682L18.9557 14.1472C18.5804 13.9595 18.1556 13.8947 17.7414 13.9618C17.3272 14.0289 16.9446 14.2246 16.6477 14.5212L15.7527 15.4162C15.3776 15.7912 15.1669 16.2998 15.1667 16.8302V20.0022M6.66675 20.0022L2.84275 16.1782C2.73019 16.0658 2.66689 15.9133 2.66675 15.7542V10.5022C2.66675 10.1044 2.82478 9.72284 3.10609 9.44154C3.38739 9.16023 3.76892 9.0022 4.16675 9.0022C4.56457 9.0022 4.9461 9.16023 5.22741 9.44154C5.50871 9.72284 5.66675 10.1044 5.66675 10.5022V15.0022"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M6.66675 16.0022L5.80875 15.1442C5.76372 15.0991 5.728 15.0457 5.70363 14.9868C5.67927 14.928 5.66673 14.8649 5.66675 14.8012C5.66675 14.6182 5.77075 14.4512 5.93475 14.3682L6.37775 14.1472C6.75306 13.9595 7.17789 13.8947 7.59209 13.9618C8.0063 14.0289 8.3889 14.2246 8.68575 14.5212L9.58075 15.4162C9.95585 15.7912 10.1666 16.2998 10.1667 16.8302V20.0022"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
