import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const LikeIcon: FC<IBaseIconProps> = ({ size = 20, color = '#fff' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M13.7265 16.6668H3.4165C3.2839 16.6668 3.15672 16.6141 3.06295 16.5203C2.96918 16.4265 2.9165 16.2994 2.9165 16.1668V8.00009C2.9165 7.86748 2.96918 7.7403 3.06295 7.64653C3.15672 7.55277 3.2839 7.50009 3.4165 7.50009H5.72317C6.01098 7.50009 6.29389 7.42556 6.54434 7.28376C6.79479 7.14196 7.00426 6.93772 7.15234 6.69092L9.41067 2.92675C9.58099 2.64246 9.84932 2.4302 10.1652 2.32988C10.481 2.22956 10.8227 2.2481 11.1258 2.38201C11.429 2.51593 11.6727 2.75598 11.8113 3.05704C11.9498 3.35809 11.9736 3.69939 11.8782 4.01675L11.0265 6.85675C11.0042 6.93134 10.9996 7.01012 11.0131 7.0868C11.0266 7.16348 11.0578 7.23594 11.1043 7.29842C11.1507 7.3609 11.2111 7.41167 11.2807 7.44668C11.3502 7.48169 11.427 7.49998 11.5048 7.50009H15.3173C15.5736 7.50003 15.8265 7.55909 16.0562 7.67265C16.286 7.78622 16.4864 7.95124 16.642 8.15488C16.7976 8.35853 16.9042 8.59531 16.9534 8.84683C17.0025 9.09835 16.9931 9.35783 16.9257 9.60509L15.334 15.4384C15.2379 15.791 15.0286 16.1022 14.7383 16.3241C14.448 16.5461 14.0928 16.6665 13.7273 16.6668H13.7265Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M5.8335 16.6667V7.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
