export const SliderRunningIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6132 8.267L9.30518 12.402L13.4402 16.537L11.3732 21.087M15.0002 7C15.5306 7 16.0393 6.78929 16.4144 6.41421C16.7895 6.03914 17.0002 5.53043 17.0002 5C17.0002 4.46957 16.7895 3.96086 16.4144 3.58579C16.0393 3.21071 15.5306 3 15.0002 3C14.4697 3 13.961 3.21071 13.586 3.58579C13.2109 3.96086 13.0002 4.46957 13.0002 5C13.0002 5.53043 13.2109 6.03914 13.586 6.41421C13.961 6.78929 14.4697 7 15.0002 7Z"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M6.40977 9.50798L9.79677 6.19897L12.6128 8.26698L15.5078 11.575H19.2298M8.89177 15.71L7.65077 16.537H4.34277"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}