import { useState } from "react";
import { ArrowExitIcon } from "../../img";
import { useNavigate } from "react-router-dom";

interface IBirthdaysContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
}

interface IDays {
    id: number,
    key: string,
    title: string
}

export const BirthdaysPage = () => {
    const isMobile = window.screen.width < 1020;
    const navigate = useNavigate();

    const [ activeDay, setActiveDay ] = useState('today')
    const [ days, setDays ] = useState<IDays[]>([
        {
            id: 1,
            key: 'today',
            title: 'Сегодня',
        },
        {
            id: 2,
            key: 'tomorrow',
            title: 'Завтра',
        },
        {
            id: 1,
            key: 'week',
            title: 'Неделя',
        },
        {
            id: 1,
            key: 'month',
            title: 'Месяц',
        },
    ])

    const [ birthdaysContent, setBirthdaysContent ] = useState<IBirthdaysContent[]>([
        {
            id: 1,
            name: 'Воробьёв Илья Романович',
            text: 'Департамент испытания и сертификации',
            position: 'Руководитель отдела',
            image: '/testImages/people/Image-1.png',
        },
        {
            id: 2,
            name: 'Кузьмин Дмитрий Константинович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-2.png',
        },
        {
            id: 3,
            name: 'Белов Дмитрий Константинович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-3.png',
        },
        {
            id: 4,
            name: 'Воробьёв Евгений Петрович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-4.png',
        },
        {
            id: 5,
            name: 'Сергеев Борис Антонович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-5.png',
        }
    ])

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    return (
        <div className='birthdays'>
            {!isMobile && (
                <div className='layout-inner__header birthdays-header'>
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Дни рождения</h1>
                    </div>

                    <div className='birthdays-calendar'>
                        { days.map((
                            day,
                            idx
                        ) => (
                            <div
                                key={ idx }
                                className={ `birthdays-calendar__block ${ day.key === activeDay ? 'active' : '' }` }
                                onClick={ () => setActiveDay(day.key) }
                            >
                                { day.title }
                            </div>
                        )) }
                    </div>
                </div>
            )}

            { isMobile && (
                <div className='birthdays-calendar'>
                    { days.map((
                        day,
                        idx
                    ) => (
                        <div
                            key={ idx }
                            className={ `birthdays-calendar__block ${ day.key === activeDay ? 'active' : '' }` }
                            onClick={ () => setActiveDay(day.key) }
                        >
                            { day.title }
                        </div>
                    )) }
                </div>
            ) }

            <div className='birthdays-content'>
                { birthdaysContent.map((
                    item,
                    idx
                ) => (
                    <div className='birthdays-block' key={ idx }>
                        <img className='birthdays-block__image' src={ item?.image } alt="" />

                        <div className='birthdays-block__content'>
                            <h1 className='birthdays-block__title'>{ item?.name }</h1>

                            <p className='birthdays-block__text'>{ item?.text }</p>

                            <p className='birthdays-block__position'>{ item?.position }</p>
                        </div>

                        <button className='birthdays-block__button'>
                            Поздравить
                        </button>
                    </div>
                )) }
            </div>
        </div>
    )
}