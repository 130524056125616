import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const ArrowExitIcon: FC<IBaseIconProps> = ({
    color = '#000',
    size = 24,
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 12H3M3 12L11.5 3.5M3 12L11.5 20.5" stroke={color} strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
