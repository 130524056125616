import React, { useState } from 'react'
import ReactCodeInput from 'react-code-input'

const props = {
    className: 'field__number__fields-block',
    inputStyleInvalid: {
        margin: '4px',
        width: '40px',
        fontSize: '18px',
        height: '26px',
        paddingLeft: '0',
        backgroundColor: 'transparent',
        borderRadius: '0',
        color: 'red',
        border: '1px solid red',
    },
}

const CodeTextField = (): any => {
    const [isPinCodeValid, setIsPinCodeValid] = useState(true)
    const [pinCode, setPinCode] = useState('')
    const [btnIsPressed, setBtnIsPressed] = useState(false)

    const handlePinChange = (value: string): void => {
        setPinCode(value)
        setBtnIsPressed(false)
    }

    return (
        <>
            <ReactCodeInput
                name={'pinCode'}
                inputMode={'tel'}
                type="number"
                isValid={isPinCodeValid}
                fields={4}
                onChange={handlePinChange}
                value={pinCode}
                {...props}
            />
        </>
    )
}

export default CodeTextField
