import React, { FC } from "react";
import { ArrowRightIcon, SettingIcon, CoinIcon, StarIcon } from "../../img";
import Button from "../../components/ui/form/Buttons/Button";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changeProfileVarsData, resetProfile, store } from "../../redux";
import { BlankAva } from "../ui/avatars/BlankAva";

interface AsideHomeProps {
    isActive: boolean;
    openMenu: () => void;
    menu: any[];
}

const AsideHome: FC<AsideHomeProps> = ({ isActive, openMenu, menu }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = {
        workspaces: []
    };

    const workspace_id = 0;
    const workspace_title = "0";
    const workspace_logo = null;

    const isCurrentWorkspace = +workspace_id !== 0;

    const isWorkspace = user.workspaces ? user.workspaces?.length >= 2 : true;

    const avatar = "/images/profile2.png";

    function handleButtonClick(): void {
        // get 'groups/view'
    }

    function openModalSlider(id: string): void {
        console.log(id);
    }

    return (
        <aside className={`nav-drawer ${isActive ? "active" : ""}`}>
            <div className="nav-drawer__content">
                <div className="nav-drawer__block">
                    <button
                        type="button"
                        className="nav-drawer__close"
                        onClick={openMenu}
                    >
                        <img src="/images/icons/close-1.svg" alt="close" />
                    </button>

                    <div className="nav-drawer__profile">
                        <div className="nav-drawer__profile-img">
                            {avatar ? (
                                    <img src="/images/profile2.png" alt="profile2" />
                                )
                                : (
                                    <BlankAva
                                        colorBg={"#000000"}
                                        colorText={"black"}
                                        text={"Мария"}
                                    />
                                )
                            }

                        </div>

                        <div className="nav-drawer__profile-name">
                            <p className="title">Мария Костина</p>

                            <div className="nav-drawer__profile-icons">
                                <div className="header__profile-star">
                                    <div className="header__profile-star-icon">
                                        <StarIcon />
                                    </div>

                                    <p className="text">10</p>
                                </div>

                                <div className="header__profile-star">
                                    <div className="header__profile-star-icon">
                                        <CoinIcon />
                                    </div>

                                    <p className="text">10</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"header__spaces"}>
                        <div
                            className={"header__spaces__name"}
                            onClick={() => navigate("/main")}
                        >
                            <img
                                src={
                                    workspace_logo ||
                                    "/images/organization/temp-logo.svg"
                                }
                                alt={"logo"}
                            />

                            <p>
                                {isCurrentWorkspace
                                    ? workspace_title
                                    : "Вступить в организацию"}
                            </p>
                        </div>

                        <div
                            className={"header__spaces__groups"}
                            onClick={() => navigate("/groups")}
                        >
                            Общая группа
                            <ArrowRightIcon color={"rgb(0, 122, 255)"} />
                        </div>

                        {isWorkspace && (
                            <Button
                                className={`btn btn-primary`}
                                onClick={handleButtonClick}
                                text={"Сменить пространство"}
                            />
                        )}
                    </div>

                    <ul className="nav-drawer__list">
                        {menu.map((item, idx) => (
                            <li
                                key={`list-item-menu-${idx}`}
                                className={`nav-drawer__item ${item.active ? "active" : ""}`}
                            >
                                <Link className="btn" to={item.link}>
                                    <img src={item.img} alt={item.text} />

                                    <span>{item.text}</span>

                                    {!!item.count && (
                                        <span
                                            className="count"
                                            style={{
                                                backgroundColor: "#007BFF",
                                                color: "white"
                                            }}
                                        >
                                            {item.count}
                                        </span>
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={"nav-drawer__footer"}>
                    <Button
                        // color={colorScheme.secondaryColor}
                        className={"btn btn-secondary"}
                        onClick={() => navigate("/workspace-settings")}
                        text={"Настройки пространства"}
                        leftIcon={
                            <SettingIcon
                                // color={'colorScheme.secondaryColor'}
                            />
                        }
                    />

                    <Button
                        // color={colorScheme.primaryColor}
                        className={"btn btn-link buttonErrorBlock"}
                        onClick={() => {
                            // if (workspace_id === 644)
                            //     return window.open(
                            //         'https://app.svoi.club/application-new?page=add&id=186&workspace_id=644',
                            //         '_self'
                            //     )

                            openModalSlider("FEEDBACK_ID");
                        }}
                        text={"Сообщить об ошибке"}
                    />

                    <Button
                        className="btn btn-secondary mb-2"
                        type={"submit"}
                        text={"Выйти"}
                        onClick={() => {
                            Cookies.remove("token");
                            dispatch(
                                changeProfileVarsData({
                                    token: ""
                                })
                            );
                            // setTimeout(() => navigate('/auth'), 300)
                            // store.dispatch(resetProfile())

                            // signOut().then()
                        }}
                    />
                </div>
            </div>
        </aside>
    );
};

export default AsideHome;
