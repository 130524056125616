import { FC, ReactElement } from 'react'
import { CheckboxIcon } from "../../../../img/icons/CheckboxIcon";

interface CheckboxProps {
    text: string | ReactElement
    isChecked: boolean
    onChange: (checked: boolean) => void
    className?: string
	id?: string
}

const Index: FC<CheckboxProps> = (
    {
        text,
        isChecked,
        onChange,
        className = '',
		id
    }
    ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <div className={'checkbox_block'}>
            <input
                type="checkbox"
                className={`custom_checkbox ${className || ''}`}
                checked={isChecked}
                onChange={handleChange}
                id={id}
            />

            <label className={'custom_checkbox_label'} htmlFor={id}>
                <span
                    className={`custom_checkbox_check ${isChecked ? 'active' : ''}`}
                >
                    {isChecked && <CheckboxIcon />}
                </span>

                {text}
            </label>
        </div>
    );
};

export default Index;
