import { useState, useEffect, FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { useLocation, useNavigate } from "react-router-dom";
import {
    SliderFeedIcon,
    SliderProfileIcon,
    SliderWorkplaceIcon,
    SliderWorkspaceIcon,
    SliderServicesIcon,
    SliderContactsIcon,
    SliderReferenceIcon,
} from "../../img";
import { FeedGroupsSlider } from "../main";
import { SliderBanners } from "./SliderBanners";

interface ISlider {
    isScrolled: boolean
}

export const Slider: FC<ISlider> = ({isScrolled}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const isMobile = window.screen.width < 1020

    const [ slidesContent, setSlidesContent ] = useState([
        {
            id: 1,
            image: '/testImages/banners/banner-4.png',
            title: 'Волейбольная команда Итэлма',
            text: 'Взяла бронзу в любительской лиге',
            layout: null,
            src: 'https://api.svoi.club/images/banners/u2Xo9oEejm.png',
            type: '0'
        },
        {
            id: 2,
            image: 'https://api.svoi.club/images/banners/78bxTAbAh9.png',
            title: 'Волейбольная команда Итэлма',
            text: 'Взяла бронзу в любительской лиге',
            layout: null,
            src: 'https://api.svoi.club/images/banners/78bxTAbAh9.png',
            type: '0'
        },
    ])

    const [ sliderRouters, setSliderRouters ] = useState([
        {
            id: 0,
            title: 'Лента',
            route: '/',
            icon: <SliderFeedIcon />
        },
        {
            id: 1,
            title: 'Моя страница',
            route: '/profile',
            icon: <SliderProfileIcon />
        },
        {
            id: 2,
            title: 'Рабочие места',
            route: '/workspace',
            icon: <SliderWorkplaceIcon />
        },
        {
            id: 3,
            title: 'Календарь',
            route: '/calendar',
            icon: <SliderWorkspaceIcon />
        },
        {
            id: 4,
            title: 'Визитница',
            route: '/contacts',
            icon: <SliderContactsIcon />
        },
        {
            id: 5,
            title: 'Справки',
            route: '/reference',
            icon: <SliderReferenceIcon />
        },
        {
            id: 6,
            title: 'Cервисы',
            route: '/service',
            icon: <SliderServicesIcon />
        },
    ])

    return (
        <div id='content_id' className={ "main__home" }>
            <div className='slider'>
                <div className='slider-bannersContent'>
                    <SliderBanners slidersNumber={ slidesContent.length } slides={ slidesContent } />
                </div>

                { !isMobile && (
                    <div id='sliderRouters'>
                        <div className={ `slider-routers ${ isScrolled ? 'scrolled' : '' }` }>
                            { sliderRouters.map((
                                router,
                                idx
                            ) => (
                                <div
                                    key={ idx }
                                    className={ `slider-routers__router 
                        ${ location.pathname === router.route ? 'active' : '' }` }
                                    onClick={ () => navigate(router.route) }
                                >
                                    { router?.icon }

                                    <p>{ router.title }</p>

                                </div>
                            )) }
                        </div>
                    </div>
                ) }
            </div>
        </div>
    )
}