export const ProfileFileIcon = () => {
    return (
        <svg width="1.25rem" height="1.25rem" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.02637 3.94727H19.9737V17.6315C19.9737 17.9107 19.8628 18.1784 19.6654 18.3758C19.468 18.5732 19.2003 18.6841 18.9211 18.6841H2.079C1.79982 18.6841 1.53208 18.5732 1.33468 18.3758C1.13727 18.1784 1.02637 17.9107 1.02637 17.6315V3.94727Z"
                fill="url(#paint0_linear_17257_66216)" />
            <path
                d="M18.1315 17.6316H2.86833C2.65895 17.6316 2.45814 17.5484 2.31009 17.4003C2.16203 17.2523 2.07886 17.0515 2.07886 16.8421V5H18.921V16.8421C18.921 17.0515 18.8378 17.2523 18.6897 17.4003C18.5417 17.5484 18.3409 17.6316 18.1315 17.6316ZM2.60517 5.52632V16.8421C2.60517 16.9119 2.6329 16.9788 2.68225 17.0282C2.7316 17.0775 2.79854 17.1053 2.86833 17.1053H18.1315C18.2013 17.1053 18.2682 17.0775 18.3176 17.0282C18.3669 16.9788 18.3946 16.9119 18.3946 16.8421V5.52632H2.60517Z"
                fill="url(#paint1_linear_17257_66216)" />
            <path
                d="M1.02637 3.42114H19.9737V17.6317C19.9737 17.9108 19.8628 18.1786 19.6654 18.376C19.468 18.5734 19.2003 18.6843 18.9211 18.6843H2.079C1.79982 18.6843 1.53208 18.5734 1.33468 18.376C1.13727 18.1786 1.02637 17.9108 1.02637 17.6317V3.42114Z"
                fill="url(#paint2_radial_17257_66216)" />
            <path
                d="M14.6706 3.68426H6.32953L6.29321 3.46426C6.24058 3.16584 6.03637 1.66005 6.84953 1.33479C8.01557 0.872689 9.24794 0.599778 10.5001 0.526367C11.7522 0.599778 12.9845 0.872689 14.1506 1.33479C14.9637 1.66005 14.7569 3.16584 14.7069 3.46426L14.6706 3.68426ZM6.78321 3.15795H14.2169C14.2695 2.61847 14.2295 1.93321 13.9537 1.82321C12.8493 1.38932 11.6842 1.12939 10.5001 1.05268C9.3155 1.12892 8.15 1.38887 7.04532 1.82321C6.77058 1.93321 6.72848 2.61847 6.78321 3.15795Z"
                fill="url(#paint3_linear_17257_66216)" />
            <path
                d="M13.9483 1.84211C13.8984 1.96663 13.8028 2.06741 13.6812 2.12385C13.5595 2.18029 13.4208 2.18814 13.2935 2.14579C12.3952 1.82081 11.4538 1.62982 10.4999 1.57895C9.5461 1.6285 8.6049 1.81862 7.7067 2.14316C7.57938 2.18646 7.44027 2.17907 7.31826 2.12252C7.19624 2.06597 7.10069 1.96461 7.05143 1.83947L6.85564 1.35C6.82919 1.28374 6.81648 1.2128 6.81828 1.14148C6.82009 1.07016 6.83638 0.999948 6.86616 0.935117C6.89595 0.870286 6.9386 0.812187 6.99153 0.76435C7.04446 0.716513 7.10656 0.679936 7.17406 0.656842C8.24333 0.271919 9.36454 0.0504806 10.4999 0C11.6352 0.0504806 12.7564 0.271919 13.8256 0.656842C13.8931 0.679936 13.9552 0.716513 14.0082 0.76435C14.0611 0.812187 14.1038 0.870286 14.1335 0.935117C14.1633 0.999948 14.1796 1.07016 14.1814 1.14148C14.1832 1.2128 14.1705 1.28374 14.1441 1.35L13.9483 1.84211Z"
                fill="url(#paint4_radial_17257_66216)" />
            <path
                d="M10.5002 15C11.9536 15 13.1318 13.8218 13.1318 12.3684C13.1318 10.915 11.9536 9.73682 10.5002 9.73682C9.04685 9.73682 7.86865 10.915 7.86865 12.3684C7.86865 13.8218 9.04685 15 10.5002 15Z"
                fill="url(#paint5_radial_17257_66216)" />
            <path
                d="M17.3611 11.089L11.5716 12.6127C10.8692 12.7975 10.1308 12.7975 9.42842 12.6127L3.63895 11.089C2.73952 10.8523 1.94377 10.3245 1.37592 9.58788C0.808068 8.85131 0.500068 7.94746 0.5 7.01741V3.94741C0.5 3.66823 0.610902 3.40049 0.808309 3.20308C1.00572 3.00568 1.27346 2.89478 1.55263 2.89478H19.4474C19.7265 2.89478 19.9943 3.00568 20.1917 3.20308C20.3891 3.40049 20.5 3.66823 20.5 3.94741V7.01741C20.4999 7.94746 20.1919 8.85131 19.6241 9.58788C19.0562 10.3245 18.2605 10.8523 17.3611 11.089Z"
                fill="url(#paint6_linear_17257_66216)" />
            <path
                d="M10.5001 11.699C10.2288 11.6989 9.95861 11.6635 9.69642 11.5937L3.90694 10.0706C3.23246 9.89306 2.63572 9.49727 2.20984 8.94495C1.78395 8.39263 1.5529 7.71485 1.55273 7.01741V2.89478H2.07905V7.01741C2.07908 7.59865 2.27154 8.16354 2.6264 8.62389C2.98125 9.08424 3.47854 9.41416 4.04063 9.56214L9.8301 11.0858C10.269 11.2006 10.7301 11.2006 11.1691 11.0858L16.9585 9.56214C17.5208 9.41435 18.0183 9.08452 18.3734 8.62415C18.7285 8.16378 18.9211 7.59879 18.9212 7.01741V2.89478H19.4475V7.01741C19.4472 7.71476 19.2161 8.39242 18.7902 8.94463C18.3643 9.49684 17.7677 9.89256 17.0933 10.07L11.3038 11.5932C11.0416 11.6631 10.7715 11.6987 10.5001 11.699Z"
                fill="url(#paint7_linear_17257_66216)" />
            <path opacity="0.1"
                  d="M11.5527 9.73682H9.44741C9.16849 9.73765 8.90123 9.84882 8.704 10.046C8.50678 10.2433 8.39561 10.5105 8.39478 10.7894V12.3421L8.65793 12.4105L8.92635 12.4789C8.92198 12.4423 8.92022 12.4053 8.92109 12.3684V10.7894C8.92109 10.6499 8.97654 10.516 9.07525 10.4173C9.17395 10.3186 9.30782 10.2631 9.44741 10.2631H11.5527C11.6923 10.2631 11.8261 10.3186 11.9248 10.4173C12.0235 10.516 12.079 10.6499 12.079 10.7894V12.3684C12.0799 12.4053 12.0781 12.4423 12.0737 12.4789L12.3421 12.4105L12.6053 12.3421V10.7894C12.6045 10.5105 12.4933 10.2433 12.2961 10.046C12.0988 9.84882 11.8316 9.73765 11.5527 9.73682Z"
                  fill="#26150A" />
            <path opacity="0.25"
                  d="M11.5527 10H9.44743C9.23805 10 9.03725 10.0832 8.88919 10.2312C8.74114 10.3793 8.65796 10.5801 8.65796 10.7895V12.4105L8.92638 12.4789C8.922 12.4423 8.92024 12.4053 8.92112 12.3684V10.7895C8.92112 10.6499 8.97657 10.516 9.07527 10.4173C9.17397 10.3186 9.30785 10.2632 9.44743 10.2632H11.5527C11.6923 10.2632 11.8262 10.3186 11.9249 10.4173C12.0236 10.516 12.079 10.6499 12.079 10.7895V12.3684C12.0799 12.4053 12.0781 12.4423 12.0737 12.4789L12.3422 12.4105V10.7895C12.3422 10.5801 12.259 10.3793 12.1109 10.2312C11.9629 10.0832 11.7621 10 11.5527 10Z"
                  fill="#26150A" />
            <path
                d="M8.9209 10.7896H12.0788V12.3685C12.0788 12.7873 11.9124 13.1889 11.6163 13.485C11.3202 13.7811 10.9186 13.9474 10.4998 13.9474C10.0811 13.9474 9.67947 13.7811 9.38336 13.485C9.08725 13.1889 8.9209 12.7873 8.9209 12.3685V10.7896Z"
                fill="url(#paint8_linear_17257_66216)" />
            <path
                d="M9.44721 10.2632H11.5525C11.6921 10.2632 11.8259 10.3186 11.9246 10.4173C12.0233 10.516 12.0788 10.6499 12.0788 10.7895V11.3158H8.9209V10.7895C8.9209 10.6499 8.97635 10.516 9.07505 10.4173C9.17376 10.3186 9.30763 10.2632 9.44721 10.2632Z"
                fill="url(#paint9_linear_17257_66216)" />
            <defs>
                <linearGradient id="paint0_linear_17257_66216" x1="10.5001" y1="18.6841" x2="10.5001" y2="3.94727"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#331C0E" />
                    <stop offset="0.039" stopColor="#4F3115" />
                    <stop offset="0.082" stopColor="#66431B" />
                    <stop offset="0.132" stopColor="#795120" />
                    <stop offset="0.192" stopColor="#855B24" />
                    <stop offset="0.27" stopColor="#8D6025" />
                    <stop offset="0.439" stopColor="#8F6226" />
                </linearGradient>
                <linearGradient id="paint1_linear_17257_66216" x1="10.4999" y1="17.6316" x2="10.4999" y2="5"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.207" stopColor="#553319" />
                    <stop offset="0.365" stopColor="#6F4D2C" />
                    <stop offset="0.83" stopColor="#B89763" />
                    <stop offset="0.933" stopColor="#BA9E6A" />
                    <stop offset="1" stopColor="#BDA572" />
                </linearGradient>
                <radialGradient id="paint2_radial_17257_66216" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(10.4306 8.96339) scale(23.7832 8.34789)">
                    <stop offset="0.347" stopColor="#452018" />
                    <stop offset="0.79" stopColor="#7A5526" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint3_linear_17257_66216" x1="10.5001" y1="3.68426" x2="10.5001" y2="0.526367"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#80580D" />
                    <stop offset="0.052" stopColor="#8C6818" />
                    <stop offset="0.173" stopColor="#A4862F" />
                    <stop offset="0.276" stopColor="#B3993D" />
                    <stop offset="0.348" stopColor="#B8A042" />
                    <stop offset="0.417" stopColor="#BCA549" />
                    <stop offset="0.516" stopColor="#C6B35C" />
                    <stop offset="0.634" stopColor="#D7C97B" />
                    <stop offset="0.764" stopColor="#EEE8A6" />
                    <stop offset="0.821" stopColor="#F9F7BB" />
                    <stop offset="0.846" stopColor="#E3DD9C" />
                    <stop offset="0.899" stopColor="#B9AB61" />
                    <stop offset="0.944" stopColor="#9A8736" />
                    <stop offset="0.979" stopColor="#87701C" />
                    <stop offset="1" stopColor="#806812" />
                </linearGradient>
                <radialGradient id="paint4_radial_17257_66216" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(10.5267 2.61201) scale(8.02684 2.94585)">
                    <stop stopColor="#BDA572" />
                    <stop offset="0.067" stopColor="#BA9E6A" />
                    <stop offset="0.17" stopColor="#B89763" />
                    <stop offset="0.453" stopColor="#744D2C" />
                    <stop offset="0.52" stopColor="#633B1F" />
                    <stop offset="0.915" stopColor="#B18D4F" />
                </radialGradient>
                <radialGradient id="paint5_radial_17257_66216" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(10.5002 12.3684) scale(2.59368)">
                    <stop offset="0.251" stopColor="#331812" />
                    <stop offset="0.65" stopColor="#4C2C18" stopOpacity="0.47" />
                    <stop offset="0.959" stopColor="#623D1E" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint6_linear_17257_66216" x1="10.5" y1="2.89478" x2="10.5" y2="12.7516"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C2AB6C" />
                    <stop offset="0.041" stopColor="#BA9D5F" />
                    <stop offset="0.118" stopColor="#AF894B" />
                    <stop offset="0.17" stopColor="#AB8244" />
                    <stop offset="0.793" stopColor="#724D1E" />
                </linearGradient>
                <linearGradient id="paint7_linear_17257_66216" x1="10.5001" y1="2.89478" x2="10.5001" y2="11.699"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BDA572" />
                    <stop offset="0.067" stopColor="#BA9E6A" />
                    <stop offset="0.17" stopColor="#B89763" />
                    <stop offset="0.635" stopColor="#6F4D2C" />
                    <stop offset="0.793" stopColor="#553319" />
                </linearGradient>
                <linearGradient id="paint8_linear_17257_66216" x1="10.4998" y1="13.9474" x2="10.4998" y2="10.7896"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#80580D" />
                    <stop offset="0.052" stopColor="#8C6818" />
                    <stop offset="0.173" stopColor="#A4862F" />
                    <stop offset="0.276" stopColor="#B3993D" />
                    <stop offset="0.348" stopColor="#B8A042" />
                    <stop offset="0.399" stopColor="#BCA549" />
                    <stop offset="0.473" stopColor="#C6B35C" />
                    <stop offset="0.56" stopColor="#D7C97B" />
                    <stop offset="0.657" stopColor="#EEE8A6" />
                    <stop offset="0.699" stopColor="#F9F7BB" />
                    <stop offset="0.732" stopColor="#E3DD9C" />
                    <stop offset="0.799" stopColor="#B9AB61" />
                    <stop offset="0.856" stopColor="#9A8736" />
                    <stop offset="0.9" stopColor="#87701C" />
                    <stop offset="0.927" stopColor="#806812" />
                </linearGradient>
                <linearGradient id="paint9_linear_17257_66216" x1="-2.13173" y1="11.3158" x2="-1.60542" y2="10.2632"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#80580D" />
                    <stop offset="0.052" stopColor="#8C6818" />
                    <stop offset="0.173" stopColor="#A4862F" />
                    <stop offset="0.276" stopColor="#B3993D" />
                    <stop offset="0.348" stopColor="#B8A042" />
                    <stop offset="0.399" stopColor="#BCA549" />
                    <stop offset="0.473" stopColor="#C6B35C" />
                    <stop offset="0.56" stopColor="#D7C97B" />
                    <stop offset="0.657" stopColor="#EEE8A6" />
                    <stop offset="0.699" stopColor="#F9F7BB" />
                    <stop offset="0.732" stopColor="#E3DD9C" />
                    <stop offset="0.799" stopColor="#B9AB61" />
                    <stop offset="0.856" stopColor="#9A8736" />
                    <stop offset="0.9" stopColor="#87701C" />
                    <stop offset="0.927" stopColor="#806812" />
                </linearGradient>
            </defs>
        </svg>

    )
}
