import { useState } from "react";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { BadgesCard, ProfileCard, StatsCard, EmployeesCard } from "../../components/layout-components";
import { FeedUsefulLinksCard } from "../../components/main";

export const ServicePage = () => {
    const [ linksContent, setLinksContent ] = useState([
        {
            id: 1,
            image: '/testImages/usefulLinks/links-1.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-1.png',
            title: 'О компании'
        },
        {
            id: 2,
            image: '/testImages/usefulLinks/links-2.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-2.png',
            title: 'Учебный портал'
        },
        {
            id: 3,
            image: '/testImages/usefulLinks/links-3.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-3.png',
            title: 'СЭД'
        },
        {
            id: 4,
            image: '/testImages/usefulLinks/links-4.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-4.png',
            title: 'Меню в столовой'
        },
        {
            id: 5,
            image: '/testImages/usefulLinks/links-5.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-5.png',
            title: 'Зарядка в офисе'
        },
        {
            id: 6,
            image: '/testImages/usefulLinks/links-6.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-6.png',
            title: '30 добрых дней'
        },
        {
            id: 7,
            image: '/testImages/usefulLinks/links-7.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-7.png',
            title: 'Стратегия развития'
        },
        {
            id: 8,
            image: '/testImages/usefulLinks/links-8.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-8.png',
            title: 'Движение вверх'
        },
        {
            id: 9,
            image: '/testImages/usefulLinks/links-9.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-9.png',
            title: 'Транспорт'
        },
        {
            id: 10,
            image: '/testImages/usefulLinks/links-10.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-10.png',
            title: 'Структура компании'
        },
        {
            id: 11,
            image: '/testImages/usefulLinks/links-11.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-11.png',
            title: 'Спорт в ВСК'
        },
        {
            id: 12,
            image: '/testImages/usefulLinks/links-12.png',
            sort: 0,
            src: '/testImages/usefulLinks/links-12.png',
            title: 'Сотрудники'
        },
    ])

    return (
        <MainLayoutContent
            leftComponents={
                <>
                    <ProfileCard/>
                    <StatsCard/>
                    <EmployeesCard/>
                    <BadgesCard/>
                </>
            }
        >
            <div className='service'>
                <div className='service-header'>
                    <h1>Все сервисы</h1>
                </div>

                <div className='service-content'>
                    {(linksContent).map((data) => <FeedUsefulLinksCard data={data} />)}
                </div>
            </div>
        </MainLayoutContent>
    )
}