import VacationHeader from "./VacationHeader";
import { VacationLeftContent } from "./VacationLeftContent";

export const VacationPage = () => {
    return (
        <div className={""}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="vacation">
                            <VacationHeader />

                            <div className={"vacation__content"}>
                                <div className={"vacation__content__left"}>
                                    <VacationLeftContent />
                                </div>

                                <div className="vacation__content__right"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};