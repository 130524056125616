import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../store"
import { mainPageInitialStateType } from './types'

export const initialStateMainPage: mainPageInitialStateType = {
    loading: false,
    data: null,
    error: null,
    success: false,
    uLinks: null,
}

const mainPageReducer = createSlice({
    name: 'mainPage',
    initialState: initialStateMainPage,
    reducers: {
        changeMainPage: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
        setULinks: (state, action: PayloadAction<any>) => {
            state.uLinks = action.payload
        },
    },
})

export const { changeMainPage, setULinks } = mainPageReducer.actions

export const getMainPageData = (state: RootState): any => state.mainPage.data
export const getULinks = (state: RootState): any => state.mainPage.uLinks

export default mainPageReducer.reducer
