import { FC } from "react";
import { IBaseIconProps } from "../types";

export const HeartIcon: FC<IBaseIconProps> = ({
    size= 24,
    color = '#000',
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill='none' xmlns="http://www.w3.org/2000/svg">
            <g opacity='0.3'>
                <path
                    d="M22.6001 8.86222C22.6001 10.4087 22.0063 11.8941 20.9459 12.9929C18.505 15.523 16.1375 18.1613 13.6054 20.5997C13.025 21.1505 12.1043 21.1304 11.5489 20.5547L4.25386 12.9929C2.04885 10.7072 2.04885 7.01723 4.25386 4.73157C6.48054 2.42345 10.108 2.42345 12.3347 4.73157L12.5999 5.00642L12.8649 4.73173C13.9325 3.6245 15.3865 3 16.9054 3C18.4243 3 19.8782 3.62444 20.9459 4.73157C22.0064 5.83045 22.6001 7.31577 22.6001 8.86222Z"
                    stroke={color} strokeWidth="2" strokeLinejoin="round" />
            </g>
        </svg>
    )
}