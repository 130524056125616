import { ArrowRightIcon } from "../../../img";
import { useState } from "react";
import { FeedNewsCard } from "./FeedNewsCard";

export const FeedNewsContent = () => {
    const [newsContent, setNewsContent] = useState([
        {
            category_id: 0,
            category_title: 'Здоровье',
            comments: '1',
            comments_count: 1,
            custom_likes_count: 1,
            custom_users_like: 0,
            date: '20 мая, 2020',
            group_id: 0,
            id: 87039,
            image: '/testImages/news/news-1.png',
            likeImage: '',
            like_image_file: '',
            like_name: 'лайк',
            likes_count: 0,
            order: 1,
            status: 1,
            title: 'Сколько нужно есть овощей и фруктов',
            views: 76,
            users_like: 0,
        },
        {
            category_id: 0,
            category_title: 'Автоклуб',
            comments: '1',
            comments_count: 1,
            custom_likes_count: 1,
            custom_users_like: 0,
            date: '20 мая, 2020',
            group_id: 0,
            id: 87039,
            image: '/testImages/news/news-2.png',
            likeImage: '',
            like_image_file: '',
            like_name: 'лайк',
            likes_count: 10,
            order: 1,
            status: 1,
            title: 'Как оформить ОСАГО при покупке подерженного ...',
            views: 76,
            users_like: 20,
        },
        {
            category_id: 0,
            category_title: 'Практики',
            comments: '1',
            comments_count: 1,
            custom_likes_count: 1,
            custom_users_like: 0,
            date: '20 мая, 2020',
            group_id: 20,
            id: 87039,
            image: '/testImages/news/news-3.png',
            likeImage: '',
            like_image_file: '',
            like_name: 'лайк',
            likes_count: 0,
            order: 1,
            status: 1,
            title: 'Несколько способов, которые помогут вам запоминать лучше',
            views: 76,
            users_like: 0,
        },
        {
            cvsfsdfsdfsdfsdfsdategory_id: 0,
            category_title: 'Практики',
            comments: '1',
            comments_count: 1,
            custom_likes_count: 1,
            custom_users_like: 0,
            date: '20 мая, 2020',
            group_id: 20,
            id: 87039,
            image: '/testImages/news/news-3.png',
            likeImage: '',
            like_image_file: '',
            like_name: 'лайк',
            likes_count: 0,
            order: 1,
            status: 1,
            title: 'Несколько способов, которые помогут вам запоминать лучше',
            views: 76,
            users_like: 0,
        },

        {
            category_id: 0,
            category_title: 'Здоровье',
            comments: '1',
            comments_count: 1,
            custom_likes_count: 1,
            custom_users_like: 0,
            date: '20 мая, 2020',
            group_id: 0,
            id: 87039,
            image: '/testImages/news/news-1.png',
            likeImage: '',
            like_image_file: '',
            like_name: 'лайк',
            likes_count: 0,
            order: 1,
            status: 1,
            title: 'Сколько нужно есть овощей и фруктов',
            views: 76,
            users_like: 0,
        },
        {
            category_id: 0,
            category_title: 'Автоклуб',
            comments: '1',
            comments_count: 1,
            custom_likes_count: 1,
            custom_users_like: 0,
            date: '20 мая, 2020',
            group_id: 0,
            id: 87039,
            image: '/testImages/news/news-2.png',
            likeImage: '',
            like_image_file: '',
            like_name: 'лайк',
            likes_count: 10,
            order: 1,
            status: 1,
            title: 'Как оформить ОСАГО при покупке подерженного ...',
            views: 76,
            users_like: 20,
        },
    ])

    return (
        <div className='feed-news'>
            <div className='feed-controls feed-news__controls' >
                {/* <div className={allViewsClass}>{allViews}</div> */}
                <h3>Новости</h3>

                <div>
                    <h4>Больше новостей</h4>
                    <ArrowRightIcon color={'#000'}/>
                </div>
            </div>

            <div className='feed-news__content'>
                {newsContent.slice(0, 6).map((item, idx) => (
                    <FeedNewsCard data={item} key={idx}/>
                ))}
            </div>
        </div>
    )
}