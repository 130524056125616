import api from '../../../api'
import { IApiHandlerParams } from '../../interface'

export const $getVacationUsers = (
    isAdmin: boolean,
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(isAdmin ? `backend/v1/vacations/booking/users${data}` : `api/v1/booking/vacations/users${data}`, {
        mock: require('./mocks/vacation.users.json'),
        handler,
    })

export const $getVacationBooking = (
    isAdmin: boolean,
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(isAdmin ? `backend/v1/vacations/booking${data}` : `api/v1/booking/vacations${data}`, {
        mock: require('./mocks/vacation.booking.json'),
        handler,
    })

export const $getVacationBookingUsers = (
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get("backend/v1/vacations/booking/users", {
        mock: require('./mocks/vacation.search.users.json'),
        request: { params: data },
        handler,
    })