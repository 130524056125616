import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../store"

export const initialStateNewsReducer: any = {
    loading: false,
    data: null,
    error: null,
    success: false,
    supply: {},
}

const newsReducer = createSlice({
    name: 'news',
    initialState: initialStateNewsReducer,
    reducers: {
        changeNews: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
    },
})

export const { changeNews } = newsReducer.actions

export const getNewsData = (state: RootState): any => state.news.data

export default newsReducer.reducer
