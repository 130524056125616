import { useState } from "react";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { BadgesCard, ProfileCard, StatsCard, EmployeesCard } from "../../components/layout-components";
import { PlusIcon2 } from "../../img";
import { DepartmentBlock } from "../../components/profile";

export const Contacts = () => {
    const isMobile = window.screen.width <= 1020
    const [departmentItems, setDepartmentItems] = useState([
        {
            id: 1,
            image: '/testImages/people/Image-1.png',
            name: 'Краюшкин Александр Витальевич',
            position: 'Руководитель',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 2,
            image: '/testImages/people/Image-2.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-2.png'
        },
        {
            id: 3,
            image: '/testImages/people/Image-3.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-3.png'
        },
        {
            id: 4,
            image: '/testImages/people/Image-4.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 5,
            image: '/testImages/people/Image-5.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-2.png'
        },
    ])

    const [businessItems, setBusinessItems] = useState([
        {
            id: 1,
            image: '/testImages/people/Image-1.png',
            name: 'Краюшкин Александр Витальевич',
            position: 'Руководитель',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 2,
            image: '/testImages/people/Image-2.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-2.png'
        },
        {
            id: 3,
            image: '/testImages/people/Image-3.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-3.png'
        },
        {
            id: 4,
            image: '/testImages/people/Image-4.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 5,
            image: '/testImages/people/Image-5.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-2.png'
        },
        {
            id: 6,
            image: '/testImages/people/Image-1.png',
            name: 'Краюшкин Александр Витальевич',
            position: 'Руководитель',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 7,
            image: '/testImages/people/Image-2.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-2.png'
        },
        {
            id: 8,
            image: '/testImages/people/Image-3.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-3.png'
        },
        {
            id: 9,
            image: '/testImages/people/Image-4.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 10,
            image: '/testImages/people/Image-5.png',
            name: 'Смирнова Виалета Михайловна',
            position: 'HR менеджер',
            icon: '/testImages/statuses/statuses-2.png'
        },
    ])

    return (
        <MainLayoutContent
            leftComponents={
                <>
                    <ProfileCard/>
                    <StatsCard/>
                    <EmployeesCard/>
                    <BadgesCard/>
                </>
            }
        >

            <div className='contacts'>
                <div className='contacts-header'>
                    <h1>Визитки</h1>

                    <div className='contacts-buttons'>
                        {/*<AppFiltersCity isSelected={isSelected} selectHandler={() => ('')} {...toggleProps}/>*/}

                        <div  className='contacts-searchButton'>
                            {/*<SearchFilter*/}
                            {/*    title='Поиск'*/}
                            {/*    onChange={(value) => ('')}*/}
                            {/*    text=''*/}
                            {/*    iconStyle={{display: 'none'}}*/}
                            {/*    isIcon*/}
                            {/*    isSearchShow*/}
                            {/*/>*/}
                        </div>

                        {!isMobile && (
                            <div className='contacts-addButton'>
                                <PlusIcon2 />
                                <p>Добавить</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='contacts-departmentBody'>
                    <div className='contacts-header'>
                        <h1> Моё подразделение </h1>
                    </div>

                    <div className='contacts-departmentContent'>
                        {departmentItems.map((item) => (
                            <DepartmentBlock key={item.id} {...item}/>
                        ))}
                    </div>
                </div>

                <div className='contacts-departmentBody'>
                    <div className='contacts-header'>
                        <h1> Все визитки </h1>
                    </div>

                    <div className='contacts-departmentContent'>
                        {businessItems.map((item) => (
                            <DepartmentBlock key={item.id} {...item}/>
                        ))}
                    </div>
                </div>

                {isMobile && (
                    <div className='contacts-addButton'>
                        <PlusIcon2 />
                        <p>Создать</p>
                    </div>
                )}
            </div>
        </MainLayoutContent>
    )
}