import { FC } from 'react'

interface IDragAndDropIconProps {
    color?: string
    opacity?: number
}

export const DragAndDropIcon: FC<IDragAndDropIconProps> = ({
    color,
    opacity = 0.3,
}) => {
    // const { colorScheme } = useContext(authContext)
    //
    // if (color === undefined) color = colorScheme.primaryColor

    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.42285 9.27307H20.4229M3.42285 16.3564H20.4229"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                opacity={opacity}
            />
        </svg>
    )
}
