import { FC } from "react";
import { NotificationMutedIcon, NotificationUnmutedIcon } from "../../img";

interface ISportBlock {
    id: number,
    weekDay: string,
    month: string,
    day: number,
    type: string,
    text: string,
    startTime: string,
    endTime: string,
    isMuted: boolean,
}
export const SportBlock: FC<ISportBlock> = ({
    id,
    weekDay,
    month,
    day,
    type,
    text,
    startTime,
    endTime,
    isMuted,
}) => {
    return (
        <div className='profile-sport__block'>
            <div className='profile-sport__block__top'>
                <div className='profile-sport__block__left'>
                    <div className='profile-sport__block__day'>
                        {day}
                    </div>

                    <div className='profile-sport__block__dates'>
                        <p className='profile-sport__block__week'>{weekDay}</p>

                        <p className='profile-sport__block__month'>{month}</p>
                    </div>
                </div>

                <div className='profile-sport__block__notification'>
                    {isMuted ? <NotificationMutedIcon/> : <NotificationUnmutedIcon/>}
                </div>
            </div>

            <div className='profile-sport__block__content'>
                <p className='profile-sport__block__type'>{type}</p>

                <h1 className='profile-sport__block__text'>{text}</h1>

                <div className='profile-sport__block__times'>
                    <p>{startTime}</p>

                    <span></span>

                    <p>{endTime}</p>
                </div>
            </div>
        </div>
    )
}