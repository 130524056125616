import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileVarsData, setUserList } from "../../../redux";
import Pagination from "../../../components/ui/form/Pagination";
import { VacationUser } from "./VacationUser";
import { $getVacationUsers } from "../../../api/requests/vacation";

export const VacationUsersAllList: FC<any> = (
    {
        isAdmin,
        isOwner,
        expand,
        handleExpand,
        filter
    }
) => {
    const dispatch = useDispatch();

    const department = null;//useSelector(getDepartment);
    const vars = useSelector(getProfileVarsData);

    const [page, setPage] = useState(1);
    const [userAllList, setUserAllList] = useState<any[]>([]);
    const [userData, setUserData] = useState<any>({});
    const [usersLoading, setUsersLoading] = useState(false);

    const handlePage = (value: number) => {
        setPage(value);
    };

    useEffect(() => {
        setUsersLoading(true);

        const requestData = (isAdmin &&
                department &&
                filter?.has_vacations !== null &&
                `page=${ page }&department_id=${ department }&has_vacations=${ filter?.has_vacations }`) ||
            (isAdmin && department && `page=${ page }&department_id=${ department }`) ||
            (filter?.has_vacations !== null && `page=${ page }&has_vacations=${ filter?.has_vacations }`) ||
            `page=${ page }`

        $getVacationUsers(
            isAdmin,
            requestData,
        ).then(res => {
            setUsersLoading(false);
            dispatch(setUserList(res?.data));
            setUserAllList(res?.data?.data || []);
            setUserData(res?.data);
        });
    }, [department, filter?.has_vacations, page]);

    if (!isOwner && !isAdmin) return null;

    return (
        <div className={"vacation__content__left__calendar-block"}>
            {(!usersLoading && userAllList.length > 0) && (
                <>
                    <div className={"vacation__content__left__calendar-head"}>
                        <div className={"vacation__content__left__calendar-label"}>Все
                            пользователи
                        </div>

                        <div
                            className={"vacation__content__left__calendar-count"}>{userData?.total}</div>
                    </div>

                    <div className={"vacation__content__left__calendar-list"}>
                        {userAllList.map((user: any) => (
                            <VacationUser
                                indicator={user?.indicator}
                                key={`${user?.id}-user`}
                                panel={`${user?.id}-user`}
                                expand={expand}
                                handleExpand={handleExpand}
                                {...{ for_user: user }}
                            />
                        ))}
                    </div>

                    {userData?.total > userData?.per_page && (
                        <div className={"vacation__content__left__calendar-pagination-wrap"}>
                            <Pagination
                                // className={ "vacation__content__left__calendar-pagination" }
                                pages={userData?.last_page}
                                activePage={page}
                                setActivePage={handlePage}
                                // disabled={ usersLoading }
                                styles={{
                                    backgroundColor: vars.colors.primaryColor,

                                    "&:hover": {
                                        backgroundColor: `${vars.colors.secondaryColor}1a`
                                    }
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
