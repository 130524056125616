import { useState } from "react";
import { ArrowRightIcon } from "../../img";

export const ServicesBlock = () => {
    const isMobile = window.screen.width <= 1020

    const [ current, setCurrent ] = useState(1)

    const [ data, setData ] = useState([
        {
            id: 1,
            number: '00019384',
            date: '29.08.2022',
            text: 'Получение справки о наличии больничн...',
            name: 'Краюшкин А. В.',
            status: 'Статус'
        },
        {
            id: 2,
            number: '00019384',
            date: '29.08.2022',
            text: 'Получение справки о наличии больничн...',
            name: 'Краюшкин А. В.',
            status: 'Статус'
        },
        {
            id: 3,
            number: '00019384',
            date: '29.08.2022',
            text: 'Получение справки о наличии больничн...',
            name: 'Краюшкин А. В.',
            status: 'Статус'
        },
    ])

    const [approveData, setApproveData] = useState([
        {
            id: 1,
            name: 'Краюшкин А.В.',
            workType: 'Работа в больничный',
            date: 'с 25.08.2024 по 30.08.2024',
            durability: '3 рабочих дня',
        },
        {
            id: 2,
            name: 'Краюшкин А.В.',
            workType: 'Работа в больничный',
            date: 'с 25.08.2024 по 30.08.2024',
            durability: '3 рабочих дня',
        }
    ])

    const approveContent = approveData.map((item, idx) => (
        <div key={ idx } style={ {boxShadow: idx === data.length - 1 ? 'unset' : ''} }
             className='profile-services-content__block profile-services-content__approveBlock'>

            <p className='profile-services-content__approveName'>{ item.name }</p>

            <p className='profile-services-content__text'>{ item.workType }</p>

            <p className='profile-services-content__text'>{ item.date }</p>

            <p className='profile-services-content__text'>{ item.durability }</p>

            <div  className='profile-services-content__button'>
                Подробнее
            </div>

            {/*<p className='profile-services-content__status'>{ item.status }</p>*/}
        </div>
    ))

    const content = data.map((item, idx) => {
        return (
            <div key={ idx }>
                { isMobile ? (
                        <div key={ idx } className='profile-services-content__mobileContent'>
                            <div className='profile-services-content__mobileBlock'>
                                <p className='profile-services-content__number'>{ item.number }</p>

                                <p className='profile-services-content__status'>{ item.status }</p>
                            </div>

                            <div>
                                <p className='profile-services-content__text'>{ item.text }</p>
                            </div>

                            <div className='profile-services-content__mobileBlock'>
                                <p className='profile-services-content__name'>{ item.name }</p>

                                <p className='profile-services-content__date'>{ item.date }</p>
                            </div>
                        </div>
                    ) :
                    (
                        <div key={ idx } style={ {boxShadow: idx === data.length - 1 ? 'unset' : ''} }
                             className='profile-services-content__block'>

                            <p className='profile-services-content__number'>{ item.number }</p>

                            <p className='profile-services-content__date'>{ item.date }</p>

                            <p className='profile-services-content__text'>{ item.text }</p>

                            <div className='profile-services-content__seperator'>

                            </div>

                            <p className='profile-services-content__name'>{ item.name }</p>

                            <p className='profile-services-content__status'>{ item.status }</p>
                        </div>
                    ) }
            </div>
        )
    })

    return (
        <div className='profile-services-content'>
            { isMobile ? (
                <>
                    <div className='profile-services-content-mobile__header'>
                        <div onClick={ () => current === 0 ? setCurrent(-1) : setCurrent(0) }
                             className={`profile-services-content-mobile__headerItem ${current === 0 ? 'opened' : ''}`}>
                            <p>Мои заявки</p>

                            <div className={`profile-services-content-mobile__header__icon ${current === 0 ? 'active' : ''}`}>
                                <ArrowRightIcon/>
                            </div>
                        </div>

                        {current === 0 && (
                            <div className='profile-services__content'>
                                {content}
                            </div>
                        )}

                        <div onClick={ () => current === 2 ? setCurrent(-1) : setCurrent(2) }
                             className={`profile-services-content-mobile__headerItem ${current === 2 ? 'opened' : ''}`}
                        >
                            <p>Согласование</p>

                            <div className='profile-services-content-mobile__headerRight'>
                                <div className='profile-services-content-mobile__headerCount'>
                                    3
                                </div>
                                <div className={`profile-services-content-mobile__header__icon ${current === 2 ? 'active' : ''}`}>
                                    <ArrowRightIcon/>
                                </div>
                            </div>
                        </div>

                        {current === 2 && (
                            <div className='profile-services__content'>
                                {approveContent}
                            </div>
                        )}

                        <div onClick={ () => current === 1 ? setCurrent(-1) : setCurrent(1) }
                             className={`profile-services-content-mobile__headerItem ${current === 1 ? 'opened' : ''}`}>
                            <p>Заявки в работе</p>

                            <div className={`profile-services-content-mobile__header__icon ${current === 1 ? 'active' : ''}`}>
                                <ArrowRightIcon/>
                            </div>
                        </div>

                        {current === 1 && (
                            <div className='profile-services__content'>
                                {content}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className='profile-services-content__header'>
                        <p className={ current === 0 ? 'profile-services-content__activeTitle' : '' }
                           onClick={ () => setCurrent(0) }>Мои заявки</p>

                        <p className={ current === 2 ? 'profile-services-content__activeTitle' : '' }
                           onClick={ () => setCurrent(2) }>Согласование
                            <span>3</span>
                        </p>

                        <p className={ current === 1 ? 'profile-services-content__activeTitle' : '' }
                           onClick={ () => setCurrent(1) }>Заявки в работе</p>
                    </div>

                    {current === 0  && (
                        <div className='profile-services__content'>
                            {content}
                        </div>
                    )}


                    {current === 1 && (
                        <div className='profile-services__content'>
                            {content}
                        </div>
                    )}


                    {current === 2  && (
                        <div className='profile-services__content'>
                            {approveContent}
                        </div>
                    )}
                </>
            ) }

        </div>
    )
}