import MobileImageAuth from '../../components/auth/MobileImageAuth'
import LoginForm from '../../components/auth/LoginForm'

export default function Auth() {
    return (
        <div className="auth__content_left_block">
            <div className="auth__content_left_block">
                <MobileImageAuth />

                <div className="auth__content_left_block_header">
                    <img
                        src="/images/auth/logo-auth.svg"
                        alt="banner"
                        width={157}
                        height={60}
                    />
                </div>

                <div className="auth__content_left_block_content">
                    <div className="auth__login auth__click">
                        <LoginForm />
                    </div>
                </div>

                <div className="auth__content_left_block_footer">
                    <div className="auth__content_help">
                        <p>
                            Помощь{' '}
                            <a href="tel:88007707867">8 (800) 770-78-67</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
