import React from 'react'
// @ts-ignore
import css from './Preloader.module.css'

interface Preloader {
    size?: string
    isCentered?: boolean
    style?: any
}

const SIZES: any = {
    extraSmall: 7,
    verySmall: 10,
    small: 16,
    medium: 24,
    large: 40,
}

export default function Preloader({
    size = 'small',
    isCentered,
    style = {},
}: Preloader) {
    const itemStyle = {
        width: SIZES[size],
        height: SIZES[size],
    }

    return (
        <div
            className={css.container}
            style={
                isCentered
                    ? {
                          position: 'absolute',
                          zIndex: 1,
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          ...style,
                      }
                    : { ...style }
            }
        >
            <div style={itemStyle} />
            <div style={itemStyle} />
            <div style={itemStyle} />
        </div>
    )
}
