import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ProfileQrCodeSmallIcon: FC<IBaseIconProps> = ({
    color= '#000'
}) => {
    return (
        <svg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M13.75 11V13.75M11 2.75V5.5M16.5 11V13.75M11 16.5H19.25M16.5 19.25H19.25M5.5 11H8.25M5.5 5.51008L5.50917 5.5M11 11.0101L11.0092 11M2.75 11.0101L2.75917 11M11 8.26008L11.0092 8.25M11 13.7601L11.0092 13.75M13.75 19.2601L13.7592 19.25M11 19.2601L11.0092 19.25M19.25 11.0101L19.2592 11M19.25 13.7601L19.2592 13.75M16.5 5.51008L16.5092 5.5M5.5 16.5101L5.50917 16.5M8.25 3.3V7.7C8.25 7.84587 8.19205 7.98576 8.08891 8.08891C7.98576 8.19205 7.84587 8.25 7.7 8.25H3.3C3.15413 8.25 3.01424 8.19205 2.91109 8.08891C2.80795 7.98576 2.75 7.84587 2.75 7.7V3.3C2.75 3.15413 2.80795 3.01424 2.91109 2.91109C3.01424 2.80795 3.15413 2.75 3.3 2.75H7.7C7.84587 2.75 7.98576 2.80795 8.08891 2.91109C8.19205 3.01424 8.25 3.15413 8.25 3.3ZM19.25 3.3V7.7C19.25 7.84587 19.1921 7.98576 19.0889 8.08891C18.9858 8.19205 18.8459 8.25 18.7 8.25H14.3C14.1541 8.25 14.0142 8.19205 13.9111 8.08891C13.8079 7.98576 13.75 7.84587 13.75 7.7V3.3C13.75 3.15413 13.8079 3.01424 13.9111 2.91109C14.0142 2.80795 14.1541 2.75 14.3 2.75H18.7C18.8459 2.75 18.9858 2.80795 19.0889 2.91109C19.1921 3.01424 19.25 3.15413 19.25 3.3ZM8.25 14.3V18.7C8.25 18.8459 8.19205 18.9858 8.08891 19.0889C7.98576 19.1921 7.84587 19.25 7.7 19.25H3.3C3.15413 19.25 3.01424 19.1921 2.91109 19.0889C2.80795 18.9858 2.75 18.8459 2.75 18.7V14.3C2.75 14.1541 2.80795 14.0142 2.91109 13.9111C3.01424 13.8079 3.15413 13.75 3.3 13.75H7.7C7.84587 13.75 7.98576 13.8079 8.08891 13.9111C8.19205 14.0142 8.25 14.1541 8.25 14.3Z'
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
