export const BannerCard = () => {
    return (
        <div className='layout-banner'>
            <div style={{background: `url("/testImages/banners/left-banner.png")`}} className='layout-banner__image'></div>

            <div className='layout-banner__content'>
                <div className='layout-banner__desc'>
                    <p>Мы даем возможность развиваться, и «выращиваем» топовых специалистов внутри компании</p>
                    <h1 className='layout-banner__title'>Карьерный рост</h1>
                </div>

                <button className='layout-banner__button'>
                    Подробности
                </button>
            </div>
        </div>
    )
}