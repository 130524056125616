import { FC, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Link, useNavigate } from "react-router-dom";
import MaskTextField from "../../../components/ui/form/Fields/MaskTextField";
import TextField from "../../../components/ui/form/Fields/TextField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import CheckBox from "../../../components/ui/form/CheckBox";
import { $getWelcomeLinkSettings } from "../../../api/requests/main";
import { $confirm, $registration } from "../../../api/requests/auth";
import { fieldModeNumeric, phoneNumberFieldMask } from "../../../utils";
import Button from "../../../components/ui/form/Buttons/Button";

const additionalRequiredFields = [
    {
        value: 'company',
        isRequired: 'welcome_link_company_field',
        label: 'Компания',
        name: 'company',
        type: 'text',
    },
    {
        value: 'city',
        isRequired: 'welcome_link_city_field',
        label: 'Город',
        name: 'city',
        type: 'text',
    },
    {
        value: 'email',
        isRequired: 'welcome_link_mail_field',
        label: 'Email',
        name: 'email',
        type: 'email',
    },
    {
        value: 'birth_date',
        isRequired: 'welcome_link_birth_field',
        label: 'Дата рождения',
        name: 'birth_date',
        type: 'date',
    },
    {
        value: 'position',
        isRequired: 'welcome_link_position_field',
        label: 'Должность',
        name: 'position',
        type: 'text',
    },
]

const Register: FC = () => {
	const navigate = useNavigate()

    const [nameState, setNameState] = useState<string>('')
    const [lastNameState, setLastNameState] = useState<string>('')
    const [phoneValue, setPhoneValue] = useState<string>('')

    const [isCheckedOffer, setIsCheckedOffer] = useState<boolean>(false)
    const [isCheckedMail, setIsCheckedMail] = useState<boolean>(false)
    const [isLoaderWelcomeLink, setIsLoaderWelcomeLink] =
        useState<boolean>(false)

    const [additionalInputs, setAdditionalInputs] = useState<any[]>([])

    const [additionalInputValues, setAdditionalInputValues] = useState<any>({
        company: '',
        city: '',
        email: '',
        birth_date: Math.floor(new Date().getTime() / 1000),
        position: '',
    })

    const invalidErrorList = [
        'NEED_ACTIVATION',
        'ALREADY_ACTIVATED',
        'CODE_INVALID',
        'PHONE_NOT_FOUND',
        'INCORRECT_PHONE',
        'NAME_NOT_FOUND',
        'PASS_NOT_FOUND',
        'MIN_PASS',
        'COMPANY_NOT_FOUND',
        'CITY_NOT_FOUND',
        'EMAIL_NOT_FOUND',
        'BIRTH_DATE_NOT_FOUND',
        'POSITION_NOT_FOUND',
        'EMAIL_INCORRECT',
    ]

    const [workspaceId, setWorkspaceId] = useState('')

    const handleChangeOffer = (is: boolean): void => {
        setIsCheckedOffer(is)
    }

    const isDisabledRed = useMemo<boolean>(() => {
        return !isCheckedMail || !isCheckedOffer
    }, [isCheckedMail, isCheckedOffer])

    const handleChangeMail = (is: boolean): void => {
        setIsCheckedMail(is)
    }

    useEffect(() => {
        setWorkspaceId(localStorage.getItem('workspaceId') || '')
        workspaceId && getWelcomeLink()
    }, [])

    useEffect(() => {
        workspaceId && getWelcomeLink()
    }, [workspaceId])

    function getWelcomeLink() {
        setIsLoaderWelcomeLink(true)

        $getWelcomeLinkSettings(workspaceId || '', { newApi: true }).then(
            (res) => {
                setIsLoaderWelcomeLink(false)

                if (!res) return

                const updateList = additionalRequiredFields.filter(
                    (item) => !!res.data?.[item.isRequired]
                )

                setAdditionalInputs(updateList)
            }
        )
    }

    function handleReg() {
        localStorage.setItem('registration-phone', phoneValue)
        const isInnerId = localStorage.getItem('workspaceInnerId')

        if (phoneValue) {
            const mainFields = {
                name: nameState,
                lastname: lastNameState,
                phone: phoneValue,
            }

            // filter additionalInputValues if they are empty
            const filteredAdditionalValues = Object.entries(
                additionalInputValues
            ).reduce((acc: any, [key, value]) => {
                if (value) {
                    acc[key] = value
                }
                return acc
            }, {})

            const registerParams = isInnerId
                ? {
                      ...mainFields,
                      inner_id: isInnerId,
                      ...filteredAdditionalValues,
                  }
                : workspaceId
                  ? {
                        ...mainFields,
                        workspace_id: workspaceId,
                        ...filteredAdditionalValues,
                    }
                  : {
                        ...mainFields,
                        ...filteredAdditionalValues,
                    }
            const confirmParams = { id: phoneValue, get_ws: 0 }

            $registration(registerParams, { formData: true }).then((res) => {
                if (invalidErrorList.includes(res?.error)) {
                    localStorage.setItem('registration-phone', phoneValue)
                    if (res.error === 'NEED_ACTIVATION') {
						navigate('/verification')

                        return null
                    }
                    // handleErrorText(res.error)

                    return
                }

                $confirm(confirmParams, { formData: true }).then((res) => {
                    if (Array.isArray(res) && res.length === 0) return

                    if (res?.ws) {
                        const wsID = res?.ws
                        localStorage.setItem('wsID', wsID)
                    }

					navigate('/verification')
                })
            })
        }
    }

    function changeAdditionalFields(fieldName: string, newValue: any) {
        if (fieldName === 'birth_date') {
            const val = moment(newValue).valueOf() / 1000
            setAdditionalInputValues((prev: any) => ({ ...prev, [fieldName]: val }))
            return
        }

        setAdditionalInputValues((prev: any) => ({ ...prev, [fieldName]: newValue }))
    }

    if (workspaceId && isLoaderWelcomeLink) return null

    return (
        <>
            <Link to={'/'} className="auth__steps registration__click">
                <span className="auth__steps-icon">
                    <img
                        width={24}
                        height={24}
                        src="/images/icons/close.svg"
                        alt="close"
                    />
                </span>

                <span className="auth__steps-text">Регистрация</span>
            </Link>

            <div className="auth__content_left_block">
                <div className="auth__content_left_block_header register">
                    <h2>СВОИ</h2>
                </div>

                <div className={'h-100'}>
                    <div className="auth__content_left_item">
                        <div className="auth__content_left_block_content auth__register_left_content">
                            <div className="auth__register registration__click">
                                <form className="auth__login__form">
                                    <div className="form-block">
                                        <MaskTextField
                                            value={phoneValue}
                                            onChange={setPhoneValue}
                                            label={'Номер телефона'}
                                            mask={phoneNumberFieldMask}
                                            autoFocus
                                            {...fieldModeNumeric}
                                        />

                                        <TextField
                                            value={nameState}
                                            onChange={setNameState}
                                            label={'Имя'}
                                            autoFocus
                                        />

                                        <TextField
                                            value={lastNameState}
                                            onChange={setLastNameState}
                                            label={'Фамилия'}
                                        />

                                        {additionalInputs.length
                                            ? additionalInputs.map(
                                                ({ label, value, type }) => {
                                                    if (type === 'date') {
                                                        return (
                                                            <>
                                                                <DatePickerForm
                                                                    key={'date'}
                                                                    label={label}
                                                                    value={additionalInputValues[value]}
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        changeAdditionalFields(
                                                                            value,
                                                                            newValue
                                                                        )
                                                                    }}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                    return (
                                                        <TextField
                                                            key={'text'}
                                                            value={additionalInputValues[value]}
                                                            onChange={(v: string) =>
                                                                changeAdditionalFields(
                                                                    value,
                                                                    v
                                                                )
                                                            }
                                                            label={label}
                                                            type={type}
                                                        />
                                                    )
                                                }
                                            )
                                            : null}
                                    </div>

                                    <CheckBox
                                        isChecked={isCheckedOffer}
										onChange={handleChangeOffer}
                                        id={'checkbox-id-1'}
                                        text={
                                            <span>
                                            Я ознакомлен с
                                            <button
                                                type={'button'}
                                                className={
                                                    'btn-text btn-link d-contents'
                                                }
                                            >
                                                {' '}
                                                Политикой конфиденциальности
                                                персональных данных{' '}
                                            </button>
                                            и даю согласие на передачу и
                                            обработку моих персональных данных
                                        </span>
                                        }
                                    />

                                    <CheckBox
                                        isChecked={isCheckedMail}
										onChange={handleChangeMail}
                                        id={'checkbox-id-2'}
                                        text={
                                            <span>
                                            Я согласен получать
                                            <button
                                                type={'button'}
                                                className={
                                                    'btn-text btn-link d-contents'
                                                }
                                            >
                                                {' '}
                                                информацию о специальных
                                                предложениях партнёров{' '}
                                            </button>
                                            (рекламные и информационные
                                            сообщения) по любым доступным
                                            каналам коммуникации
                                        </span>
                                        }
                                    />

                                    <Button
                                        text={'Зарегистрироваться'}
                                        className={'btn btn-primary'}
                                        onClick={handleReg}
                                        disabled={isDisabledRed}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="auth__content_left_block_footer">
                        <div className="auth__content_step">
                            <Link
                                to={'/'}
                                className="btn btn-link registration__click show"
                            >
                                Есть аккаунт? <span>Войти</span>
                            </Link>
                        </div>

                        <div className="auth__content_help">
                            <p>
                                Помощь
                                <Link to={'tel:88007707867'}>
                                    {' '}
                                    8 (800) 770-78-67
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register
