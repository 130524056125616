import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const EmployeesCard = () => {
    const navigate = useNavigate()
    const [thanksContent, setThanksContent] = useState([
        {
            card: "https://api.svoi.club/images/workspaces_badges/hG3F6OtFuV_1158x618.png",
            causes: ['Потому что твои успехи положительно влияют на результат компании'],
            comment: "",
            date: 1713362309,
            description: "<p>Дорогие коллеги! От всей души поздравляю вас с наступающим Новым годом! Пусть мечты, не сбывшиеся в прошлых годах, в наступающем обязательно сбудутся! Пусть все задуманные идеи и задачи воплотятся в жизнь. Пусть новые и неизученные горизонты придут в вашу жизнь и откроют новые стремления и новые мечты. Желаю вам неугасаемой жизненной энергии, финансового достатка, карьерного продвижения и безопасности. Пусть заветные составляющие: сильная любовь, успешная карьера, крепкое здоровье станут главными спутниками по жизни.</p>",
            id: 129950,
            image: "https://api.svoi.club/images/workspaces_badges/RpUvL2xXdY_618x618.png",
            privacy: "1",
            privacy_messenger: "0",
            sender_id: 164795,
            sender_name: "Нигматов Тимур ",
            sender_photo: "/testImages/thanks/thanks-1.png",
            title: "С Новым 2023 Годом!!!! Уважаемые коллеги",
        },
        {
            card: "https://api.svoi.club/images/workspaces_badges/hG3F6OtFuV_1158x618.png",
            causes: ['Потому что твои успехи положительно влияют на результат компании'],
            comment: "",
            date: 1713362309,
            description: "<p>Дорогие коллеги! От всей души поздравляю вас с наступающим Новым годом! Пусть мечты, не сбывшиеся в прошлых годах, в наступающем обязательно сбудутся! Пусть все задуманные идеи и задачи воплотятся в жизнь. Пусть новые и неизученные горизонты придут в вашу жизнь и откроют новые стремления и новые мечты. Желаю вам неугасаемой жизненной энергии, финансового достатка, карьерного продвижения и безопасности. Пусть заветные составляющие: сильная любовь, успешная карьера, крепкое здоровье станут главными спутниками по жизни.</p>",
            id: 129950,
            image: "https://api.svoi.club/images/workspaces_badges/RpUvL2xXdY_618x618.png",
            privacy: "1",
            privacy_messenger: "0",
            sender_id: 164795,
            sender_name: "Нигматов Тимур ",
            sender_photo: "/testImages/thanks/thanks-2.png",
            title: "С Новым 2023 Годом!!!! Уважаемые коллеги",
        },
        {
            card: "https://api.svoi.club/images/workspaces_badges/hG3F6OtFuV_1158x618.png",
            causes: ['Потому что твои успехи положительно влияют на результат компании'],
            comment: "",
            date: 1713362309,
            description: "<p>Дорогие коллеги! От всей души поздравляю вас с наступающим Новым годом! Пусть мечты, не сбывшиеся в прошлых годах, в наступающем обязательно сбудутся! Пусть все задуманные идеи и задачи воплотятся в жизнь. Пусть новые и неизученные горизонты придут в вашу жизнь и откроют новые стремления и новые мечты. Желаю вам неугасаемой жизненной энергии, финансового достатка, карьерного продвижения и безопасности. Пусть заветные составляющие: сильная любовь, успешная карьера, крепкое здоровье станут главными спутниками по жизни.</p>",
            id: 129950,
            image: "https://api.svoi.club/images/workspaces_badges/RpUvL2xXdY_618x618.png",
            privacy: "1",
            privacy_messenger: "0",
            sender_id: 164795,
            sender_name: "Нигматов Тимур ",
            sender_photo: "/testImages/thanks/thanks-1.png",
            title: "С Новым 2023 Годом!!!! Уважаемые коллеги",
        },
        {
            card: "https://api.svoi.club/images/workspaces_badges/hG3F6OtFuV_1158x618.png",
            causes: ['Потому что твои успехи положительно влияют на результат компании'],
            comment: "",
            date: 1713362309,
            description: "<p>Дорогие коллеги! От всей души поздравляю вас с наступающим Новым годом! Пусть мечты, не сбывшиеся в прошлых годах, в наступающем обязательно сбудутся! Пусть все задуманные идеи и задачи воплотятся в жизнь. Пусть новые и неизученные горизонты придут в вашу жизнь и откроют новые стремления и новые мечты. Желаю вам неугасаемой жизненной энергии, финансового достатка, карьерного продвижения и безопасности. Пусть заветные составляющие: сильная любовь, успешная карьера, крепкое здоровье станут главными спутниками по жизни.</p>",
            id: 129950,
            image: "https://api.svoi.club/images/workspaces_badges/RpUvL2xXdY_618x618.png",
            privacy: "1",
            privacy_messenger: "0",
            sender_id: 164795,
            sender_name: "Нигматов Тимур ",
            sender_photo: "/testImages/thanks/thanks-2.png",
            title: "С Новым 2023 Годом!!!! Уважаемые коллеги",
        },
        {
            card: "https://api.svoi.club/images/workspaces_badges/hG3F6OtFuV_1158x618.png",
            causes: ['Потому что твои успехи положительно влияют на результат компании'],
            comment: "",
            date: 1713362309,
            description: "<p>Дорогие коллеги! От всей души поздравляю вас с наступающим Новым годом! Пусть мечты, не сбывшиеся в прошлых годах, в наступающем обязательно сбудутся! Пусть все задуманные идеи и задачи воплотятся в жизнь. Пусть новые и неизученные горизонты придут в вашу жизнь и откроют новые стремления и новые мечты. Желаю вам неугасаемой жизненной энергии, финансового достатка, карьерного продвижения и безопасности. Пусть заветные составляющие: сильная любовь, успешная карьера, крепкое здоровье станут главными спутниками по жизни.</p>",
            id: 129950,
            image: "https://api.svoi.club/images/workspaces_badges/RpUvL2xXdY_618x618.png",
            privacy: "1",
            privacy_messenger: "0",
            sender_id: 164795,
            sender_name: "Нигматов Тимур ",
            sender_photo: "/testImages/thanks/thanks-2.png",
            title: "С Новым 2023 Годом!!!! Уважаемые коллеги",
        }
    ])

    return (
        <div  className='layout-thanks' onClick={() => navigate('/colleagues')}>
            <div className='layout-thanks__title'>
                Новые сотрудники
            </div>

            <div className='layout-thanks__list'>
                {thanksContent?.slice(0, 2)?.map(({ id, sender_name, sender_photo},  index) => {
                    return (
                        <div key={index} className='layout-thanks__item'>
                            {sender_photo && <img src={sender_photo} />}

                            {!sender_photo && sender_name && <div className='layout-thanks__name'>{sender_name?.slice(0, 1)}</div>}
                        </div>
                    );
                })}

                {thanksContent?.length > 2 && <div className='layout-thanks__count'>{`+${thanksContent.length - 2}`}</div>}
            </div>

        </div>
    )
}
