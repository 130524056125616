import DialogContainer from "../ui/dialog/DialogContainer";
import { FC, useState } from "react";
import * as repl from "repl";
import { EmailIcon, PenIcon, PhoneIcon, SearchIcon } from "../../img";
import { SearchItem } from "../ui/form/SearchItem";

interface IProfileDepartmentBlock {
    id?: number,
    name: string;
    image: string;
    position: string;
    icon: string;
    isMessage?:boolean;
    department?: string;
}

interface IVacationCard {
    data: IProfileDepartmentBlock[]
}
export const VacationCard: FC<IVacationCard> = ({data}) => {
    const isMobile = window.screen.width < 768
    const [isDialog, setIsDialog] = useState(false)

    const [selectedReplacer, setSelectedReplacer] = useState<number>(-1)
    const [replacer, setReplacer] = useState<any>(null)

    const handleSelectReplacer = () => {
        if (selectedReplacer === -1) return

        setIsDialog(false)
        setReplacer(data[selectedReplacer])
    }

    return (
        <div className='layout-vacation'>
            <div className='layout-vacation__header'>
                <h5>До отпуска</h5>

                <h1>8 дней</h1>
            </div>

            <div className='layout-vacation__dateWrapper'>
                <h3>Ближайший отпуск</h3>

                <div className='layout-vacation__dateContent'>
                    <p>21.02.2023</p>
                    <p>21.02.2023</p>
                </div>
            </div>

            <div className='layout-vacation__body'>
                <p>Количество неотгуленных дней</p>
                <h3>14</h3>
            </div>

            {replacer && (
                <div className='layout-vacation-replacer'>
                    <p className='layout-vacation-replacer__title'>Замещающий</p>

                    <div className='layout-vacation-replacer__content'>
                        <div className='layout-vacation-replacer__content__top'>
                            <img src={replacer?.image} alt="" />

                            <div onClick={() => setIsDialog(true)}>
                                <PenIcon/>
                            </div>
                        </div>

                        <div className='layout-vacation-replacer__content__desc'>
                            <h1>{replacer?.name}</h1>

                            <div className='layout-vacation-replacer__content__contact'>
                                <PhoneIcon/>
                                <p>+7 925 353-01-10</p>
                            </div>

                            <div className='layout-vacation-replacer__content__contact'>
                                <EmailIcon/>
                                <p>ivanov@itelma.ru</p>
                            </div>
                        </div>

                    </div>
                </div>
            )}

            <div className='layout-vacation__buttons'>
                {!replacer &&
                  <button onClick={() => setIsDialog(true)} className='layout-vacation__replaceButton'>Выбрать замещающего</button>
                }
                <button className='layout-vacation__calendarButton'>Календарь отпусков</button>
            </div>


            <DialogContainer
                isOpen={isDialog}
                closeModal={() => setIsDialog(false)}
            >
                <div className='layout-vacation-modal'>
                    <div className='layout-vacation-modal__header'>
                        <h1 className='layout-vacation-modal__title'>Выбор замещающего</h1>

                        <div className='layout-vacation-modal__search'>
                            <SearchIcon color='#000'/>

                            <input type="search"  placeholder='Поиск'/>
                        </div>
                    </div>

                    <div className='layout-vacation-modal__content'>
                        {data.map((item, idx) => (
                            <div key={idx} className='layout-vacation-modal__block'>
                                <input
                                    type="checkbox"
                                    checked={idx === selectedReplacer}
                                    onClick={() => idx === selectedReplacer ? setSelectedReplacer(-1) : setSelectedReplacer(idx)}
                                />

                                <div className='layout-vacation-modal__block__right'>
                                    <img src={item.image} alt="" />

                                    <div className='layout-vacation-modal__block__desc'>
                                        <h1>{item.name}</h1>

                                        <p className='layout-vacation-modal__block__desc__position'>{item.position}</p>

                                        <p className='layout-vacation-modal__block__desc__department'>{item.department}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='layout-vacation-modal__linearButton'>
                        <button style={{ opacity: selectedReplacer === -1 ? '0.5' : 'unset'}} className='layout-vacation-modal__button' onClick={handleSelectReplacer}>
                            Выбрать
                        </button>
                    </div>
                </div>
            </DialogContainer>
        </div>
    )
}