import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getDepartment,
    getMonth,
    getProfileVarsData,
    setBookingList,
    setBookingLoading
} from "../../../redux";
import { Skeleton } from "../../../components/ui/skeleton";
import { VacationUser } from "./VacationUser";
import Pagination from "../../../components/ui/form/Pagination";
import { $getVacationBooking } from "../../../api/requests/vacation";

export const VacationUsersCalendarList: FC<any> = (
    {
        isAdmin,
        isOwner,
        expand,
        handleExpand,
        filter
    }
) => {
    const dispatch = useDispatch();
    const department = useSelector(getDepartment);
    const month = useSelector(getMonth);
    const vars = useSelector(getProfileVarsData);

    const [page, setPage] = useState(1);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [isLoadFirstPage, setIsLoadFirstPage] = useState(false);
    const [bookingUsersList, setBookingUsersList] = useState<any[]>([]);
    const [userData, setUserData] = useState<any>({});

    const handlePage = (value: number) => {
        setPage(value);

        setIsLoadFirstPage(true);
    };

    useEffect(() => {
        if (isBookingLoading || ((page === 1) && !isLoadFirstPage)) return;

        setIsLoadFirstPage(false);

        getRequestBooking();
    }, [page]);

    useEffect(() => {
        if (isBookingLoading) return;

        if (page === 1) return getRequestBooking();

        setPage(1);
    }, [department, month]);

    useEffect(() => {
        dispatch(setBookingLoading(isBookingLoading));
    }, [isBookingLoading]);

    function getRequestBooking() {
        setIsBookingLoading(true);

        const requestData = department
            ? `page=${ page }&date=${ month }&department_id=${ department }`
            : `page=${ page }&date=${ month }`

        $getVacationBooking(
            isAdmin,
            requestData,
        ).then(res => {
            setUserData(res)

            const filterList = res.data.filter((user: any) => (filter?.status ? user?.status === filter?.status : true));

            dispatch(setBookingList(filterList));

            setBookingUsersList(filterList);
            setPage(res.current_page)

            setIsBookingLoading(false);
        });
    }

    if (!isAdmin && !isOwner) return null;

    return (
        <div
            className={"vacation__content__left__calendar-block"}
        >
            {isBookingLoading ? (
                <div className={"vacation__content__left__calendar-block"}>
                    {[1,2,3,4,5].map((_, index) => (
                        <Skeleton key={`vacation-skeleton-item-${index}`} />
                    ))}
                </div>
            ) : (
                <>
                    <div className={"vacation__content__left__calendar-head"}>
                        <div className={"vacation__content__left__calendar-label"}>В календаре</div>

                        <div
                            className={"vacation__content__left__calendar-count"}
                            style={{
                                color: vars.colors.fourthColor,
                                backgroundColor: vars.colors.secondaryColor,
                            }}
                        >
                            {userData?.total || 0}
                        </div>
                    </div>

                    <div className={"vacation__content__left__calendar-list"}>
                        {bookingUsersList && bookingUsersList.map((user: any, index: number) => {
                            return (
                                <VacationUser
                                    key={`vacation-user-item-${user?.id || index}`}
                                    panel={user?.id}
                                    expand={expand}
                                    handleExpand={handleExpand}
                                    indicator={index}
                                    {...user}
                                />
                            );
                        })}
                    </div>

                    {userData?.total > userData?.per_page && (
                        <div className={"vacation__content__left__calendar-pagination-wrap"}>
                            <Pagination
                                // className={"vacation__content__left__calendar-pagination"}
                                pages={userData?.last_page}
                                activePage={page}
                                setActivePage={handlePage}
                                // disabled={isBookingLoading}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
