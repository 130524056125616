export const successNotify = async (text = 'OK') => {
    const IziToast: any = await import('izitoast')

    IziToast.success({
        message: text,
        timeout: 3000,
    })
}
export const failureNotify = async (text = 'Error', timeout = 3000) => {
    const IziToast: any = await import('izitoast')

    IziToast.error({
        message: text,
        timeout,
    })
}
