import { useState } from "react";

export const StatusCard = () => {
    const [data, setData] = useState([
        {
            id: 1,
            image: "/testImages/statuses/statuses-1.png",
        },
        {
            id: 2,
            image: "/testImages/statuses/statuses-2.png",
        },
        {
            id: 3,
            image: "/testImages/statuses/statuses-3.png",
        },
        {
            id: 4,
            image: "/testImages/statuses/statuses-4.png",
        },
    ])

    return (
        <div className='layout-badges'>
            <div className='layout-badges__title'>
                Статусы

                <div className='layout-badges__more'>
                    Показать все
                </div>
            </div>

            <div className='layout-badges__list'>
                {data.slice(0, 4)?.map(({ title: badgeTitle, image, causes, badge_id, workspace_badge }: any, idx: number) => {
                    return (
                        <div
                            key={idx}
                            className='layout-badges__item'
                            // onClick={(e) => {
                            //   openBadgesListModal(idx, e);
                            // }}
                        >
                            <img src={image} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}