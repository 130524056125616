import { useState } from "react";

export const CongratulatedCard = () => {
    const [data, setData] = useState([
        {
            id: 1,
            image: '/testImages/people/Image-1.png'
        },
        {
            id: 2,
            image: '/testImages/people/Image-2.png'
        },
        {
            id: 3,
            image: '/testImages/people/Image-3.png'
        },
        {
            id: 4,
            image: '/testImages/people/Image-4.png'
        }, {
            id: 5,
            image: '/testImages/people/Image-7.png'
        },
        {
            id: 6,
            image: '/testImages/people/Image-6.png'
        },
        {
            id: 7,
            image: '/testImages/people/Image-7.png'
        },
        {
            id: 8,
            image: '/testImages/people/Image-8.png'
        },
        {
            id: 9,
            image: '/testImages/people/Image-9.png'
        },

    ])

    return (
        <div className='layout-congratulations'>
            <div className='layout-congratulations__header'>
                <h1>Поздравили</h1>

                <p>{data.length}</p>
            </div>
            
            <div className='layout-congratulations__content'>
                {data.slice(0, 6).map((item, idx) => (
                    <div className={`layout-congratulations__block ${idx === 0 ? 'first' : ''}`} key={idx}>
                        <img src={item.image} alt="" />
                    </div>
                ))}
            </div>
        </div>
    )
}