import { useState } from "react";

interface IWork {
    position: string,
    department: string,
    workplace: string,
    birth: string,
    number: string,
    email: string,
    skills: ISkills[]
}

interface ISkills {
    id: number,
    value: string
}
export const PersonalWorkBlock = () => {
    const [data, setData] = useState<IWork>({
        position: 'Менеджер по продажам',
        department: 'Испытания и сертификации',
        workplace: 'Москва, Островная 4',
        birth: '15.06.1988',
        number: '+7 (925) 353-03-30',
        email: 'ivanov@itelma.ru',
        skills: [
            {
                id: 1,
                value: 'Анализирует требования заказчика',
            },
            {
                id: 2,
                value: 'Разрабатывает тесты',
            },
            {
                id: 3,
                value: 'Figma',
            },
            {
                id: 4,
                value: 'Agale SAFe',
            },
            {
                id: 5,
                value: '1С Предприятие',
            },
            {
                id: 6,
                value: 'Коммуникация с проектной командой',
            },
        ]
    })

    return (
        <div className='profile-work'>
            <div className='profile-work__content'>
                <div className='profile-work__block'>
                    <p className='profile-work__block__placeholder'>Должность</p>

                    <h1 className='profile-work__block__value'>{data.position}</h1>
                </div>

                <div className='profile-work__block'>
                    <p className='profile-work__block__placeholder'>Департамент</p>

                    <h1 className='profile-work__block__value'>{data.department}</h1>
                </div>

                <div className='profile-work__block'>
                    <p className='profile-work__block__placeholder'>Рабочее место</p>

                    <h1 className='profile-work__block__value'>{data.workplace}</h1>
                </div>

                <div className='profile-work__block'>
                    <p className='profile-work__block__placeholder'>Дата рождения</p>

                    <h1 className='profile-work__block__value'>{data.birth}</h1>
                </div>

                <div className='profile-work__block'>
                    <p className='profile-work__block__placeholder'>Номер телефона</p>

                    <h1 className='profile-work__block__value'>{data.number}</h1>
                </div>

                <div className='profile-work__block'>
                    <p className='profile-work__block__placeholder'>email</p>

                    <h1 className='profile-work__block__value'>{data.email}</h1>
                </div>
            </div>

            <div className='profile-work__line'></div>

            <div className='profile-work__skills'>
                <h1 className='profile-work__skills__title'>Навыки</h1>

                <div className='profile-work__skills__content'>
                    {data.skills.map((skill) => (
                        <div className='profile-work__skills__block'>
                            {skill.value}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}