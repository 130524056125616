import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ReplacingIcon: FC<IBaseIconProps> = ({
    size = 24,
    color = '#00A7B5',
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1654 8C19.6223 4.46819 16.0981 2 11.9975 2C6.81214 2 2.5486 5.94668 2.04688 11"
                  stroke={color} strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
            <path d="M18 8H21.4C21.7314 8 22 7.73137 22 7.4V4" stroke={color} strokeWidth="2" stroke-linecap="round"
                  strokeLinejoin="round" />
            <path d="M2.88281 16C4.42593 19.5318 7.95009 22 12.0508 22C17.2361 22 21.4997 18.0533 22.0014 13"
                  stroke={color} strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
            <path d="M6.04688 16H2.64687C2.3155 16 2.04688 16.2686 2.04688 16.6V20" stroke={color} strokeWidth="2"
                  stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    )
}