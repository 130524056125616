import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import 'moment/locale/ru'
import moment, { Moment } from 'moment'
import { FC } from "react";

interface DatePickerForm {
    label: string
    value: number
    className?: string
    onChange?: (value: Moment | null) => void
}

const DatePickerForm: FC<DatePickerForm> = ({
    label,
    value,
    className,
    onChange,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
            <DatePicker
                className={`${className} form-control form-datepicker`}
                format="DD.MM.YYYY"
                label={label}
                value={moment.unix(value)}
                onChange={onChange}
                disableFuture
            />
        </LocalizationProvider>
    )
}

export default DatePickerForm
