import { useState } from "react";


export const BadgesCard = () => {
    const [badgesContent, setBadgesContent] = useState<any>([
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372793,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            }
        },
        {
            badge_id: 265,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-2.png",
            image: "/testImages/badges/badges-2.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-2.png",
                image: "/testImages/badges/badges-2.png",
            }
        },
        {
            badge_id: 266,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-3.png",
            image: "/testImages/badges/badges-3.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-3.png",
                image: "/testImages/badges/badges-3.png",
            }
        },
        {
            badge_id: 267,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-4.png",
            image: "/testImages/badges/badges-4.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-4.png",
                image: "/testImages/badges/badges-4.png",
            }
        },
        {
            badge_id: 272,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-5.png",
            image: "/testImages/badges/badges-5.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 267,
                card: "/testImages/badges/badges-5.png",
                image: "/testImages/badges/badges-5.png",
            }
        },

        {
            badge_id: 268,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-6.png",
            image: "/testImages/badges/badges-6.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-6.png",
                image: "/testImages/badges/badges-6.png",
            }
        },
        {
            badge_id: 269,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-7.png",
            image: "/testImages/badges/badges-7.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-7.png",
                image: "/testImages/badges/badges-7.png",
            }
        },
        {
            badge_id: 270,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-8.png",
            image: "/testImages/badges/badges-8.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-8.png",
                image: "/testImages/badges/badges-8.png",
            }
        },
        {
            badge_id: 270,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-8.png",
            image: "/testImages/badges/badges-8.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-8.png",
                image: "/testImages/badges/badges-8.png",
            }
        },
    ])

    const groupData = () => {
        return [{}, ...badgesContent].reduce((acc, curr) => {
            const key = curr.badge_id;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        });
    };

    const filterData = badgesContent?.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.badge_id === v.badge_id) === i);

    const checkBadgesSize = (badgeId: number) => groupData()[badgeId]?.length;

    return (
        <div className='layout-badges'>
            <div className='layout-badges__title'>
                {'Благодарности'}

                <div className='layout-badges__more'>
                    Показать все
                </div>
            </div>

            <div className='layout-badges__list'>
                {filterData.slice(0, 4)?.map(({ title: badgeTitle, image, causes, badge_id, workspace_badge }: any, idx: number) => {
                    const isBadgesSize = checkBadgesSize(badge_id);

                    return (
                        <div
                            key={idx}
                            className='layout-badges__item'
                            // onClick={(e) => {
                            //   openBadgesListModal(idx, e);
                            // }}
                        >
                            <img src={image} />

                            {isBadgesSize > 1 && <div className='layout-badges__itemCount'>{isBadgesSize}</div>}
                        </div>
                    );
                })}
            </div>

            {/*{!length && <div className={styles.empty}>Список пуст</div>}*/}
        </div>
    )
}
