import { ProfileStarIcon, ProfileBalanceIcon } from "../../img/icons/layout-components";

export const StatsCard = () => {
    return (
        <div className='layout-stats'>
            <div className='layout-stats__block'>
                <div style={{background: '#97CA001A'}} className='layout-stats__circle'><ProfileStarIcon/> </div>
                <p>10</p>
            </div>

            <div className='layout-stats__block'>
                <div className='layout-stats__circle' style={{background: '#FF7E001A'}}> <ProfileBalanceIcon/></div>
                <p>10</p>
            </div>
        </div>
    )
}
