import React, { FC } from 'react'

interface ICloseIConProps {
    color?: string
    width?: string | number
    height?: string | number
    opacity?: string | number
    strokeWidth?: string | number
}
export const CloseIcon: FC<ICloseIConProps> = ({
    color = 'white',
    width = 16,
    height = 16,
    opacity = 1,
    strokeWidth = 2,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M1.34313 12.6569L6.99999 7M12.6568 1.34315L6.99999 7M6.99999 7L1.34313 1.34315M6.99999 7L12.6568 12.6569"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    )
}
