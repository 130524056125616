import { FC } from "react";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { CommentIcon, EyeIcon, LikeIcon } from "../../../img";
import TextOverflow from "../../../utils/text-overflow/TextOverflow";

interface NewsCard {
    title: string;
    image: string;
    category_title: string;
    date: string;
    views: number;
    likes_count: number;
    comments_count: number;
}

interface IFeedNewsCard {
    data: NewsCard
}

export const FeedNewsCard: FC<IFeedNewsCard> = ({data}) => {
    const isMobile = window.screen.width <= 600
    const {
        title,
        image,
        category_title,
        date,
        views,
        likes_count,
        comments_count,
    } = data
    return (
        <div className='feed-news-card'>
            <img src={ image } />

            <div className='feed-news-card__infoBox'>
                <h5>{ TextOverflow({title, lines: 3}) }</h5>

                { category_title ? <div className={ `feed-news-card__catTitleStyle` }>{ category_title }</div> : null }

                <div className='feed-news-card__content'>
                    <div className='feed-news-card__dateBox'>
                        <NewsDateIcon size={ '1.25rem' }
                                      style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} } />
                        <div>{ date }</div>
                    </div>

                    <div className='feed-news-card__stats'>
                        <div className='feed-news-card__stats__block'>
                            <EyeIcon/>

                            <h1>{views}</h1>
                        </div>

                        <div className='feed-news-card__stats__block'>
                            <CommentIcon/>

                            <h1>{comments_count}</h1>
                        </div>

                        <div className='feed-news-card__stats__block'>
                            <LikeIcon color='#00A7B5'/>

                            <h1>{likes_count}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}