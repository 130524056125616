import React, { useState } from "react";
import { ProfileFileIcon, ProfileQrCodeSmallIcon } from "../../img/icons/layout-components";
import { useLocation, useNavigate } from "react-router-dom";

export const ProfileCard = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [ openPopup, setOpenPopup ] = useState(false);

    const handlePopup = () => setOpenPopup(!openPopup);

    return (
        <>
            <div className='layout-profile'>
                <div className='layout-profile__avaBox'  /*  onClick={() => push('/profile-settings')} */
                     style={ {cursor: 'pointer'} }>
                    <img src={ '/testImages/people/Image-1.png' } className='layout-profile__avatar'
                         style={ {marginRight: '0'} } />
                </div>

                <div className='layout-profile__nameBlock'>
                    <h1>Семеренко Александр
                        Александрович</h1>

                    <div>
                        <ProfileFileIcon />

                        <p>На месте</p>
                    </div>
                </div>


                { location.pathname === '/profile' ? (
                    <div className='layout-profile__buttons'>
                        <button className='layout-profile__buttons__qrCodeButton'>
                            <ProfileQrCodeSmallIcon color='#00A7B5'/><p>  Моя визитка</p>
                        </button>

                        <button className='layout-profile__buttons__adaptationButton'>Адаптация</button>
                    </div>
                ) : (
                    <button className='layout-profile__businessCard' type='button'
                            onClick={ () => navigate('profile') }>
                        Моя страница
                    </button>
                ) }


            </div>

        </>
    )
}
