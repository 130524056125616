import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const ArrowRightIcon: FC<IBaseIconProps> = ({
    color = '#000',
    size = 24,
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.7726 12.2984L8.95161 8.47737C8.53761 8.06337 8.53761 7.39137 8.95161 6.97737C9.36561 6.56337 10.0376 6.56337 10.4516 6.97737L15.0656 11.5914C15.4566 11.9824 15.4566 12.6154 15.0656 13.0054L10.4516 17.6194C10.0376 18.0334 9.36561 18.0334 8.95161 17.6194C8.53761 17.2054 8.53761 16.5334 8.95161 16.1194L12.7726 12.2984Z"
                fill={color} />
        </svg>
    )
}
