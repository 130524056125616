import Button from "../../../components/ui/form/Buttons/Button";
import { ArrowExitIcon, PlusIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const VacationHeader = () => {
    const navigate = useNavigate();

    const [calendarBtnList, setCalendarBtnList] = useState([
        {
            key: "week",
            text: "Неделя",
            active: false
        },
        {
            key: "month",
            text: "Месяц",
            active: true
        }
    ])

    const handleChangeActive = (item: any) => {
        const updateList = [...calendarBtnList].map(i => {
            i.active = item.key === i.key;

            return i
        })

        setCalendarBtnList(updateList)
    };

    const handleOpenPopup = () => {
    };

    const handleBack = () => {
        navigate(`/calendar`);
    };

    return (
        <div className={'vacation__header'}>
            <div className={'vacation__header__title'}>
                <div className={'back'} onClick={handleBack}>
                    <ArrowExitIcon color={"black"}/>
                </div>

                <h1>Отпуска</h1>

                <p onClick={() => navigate("/calendar/medical")}>Больничный</p>
                <p onClick={() => navigate("/calendar/business")}>Командировки</p>
            </div>

            <div className="vacation__header__btns">
                <div className={"vacation__header__btns-calendar"}>
                    {calendarBtnList.map((item, idx) => (
                        <Button
                            key={`calendar-btn-item-${idx}`}
                            className={`btn btn-${item.active ? "blue" : "secondary"}`}
                            onClick={() => handleChangeActive(item)}
                            text={item.text}
                        />
                    ))}
                </div>

                <Button
                    className={'btn btn-primary'}
                    onClick={handleOpenPopup}
                    leftIcon={<PlusIcon />}
                    text={"Добавить"}
                />
            </div>
        </div>
    );
};

export default VacationHeader;