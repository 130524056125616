import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const AnalyticsArrowRightIcon: FC<IBaseIconProps> = ({
    color = '#262630',
    size = 21,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.4751 15.5586L3.41676 10.5003L8.4751 5.44193"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.5835 10.5L3.5585 10.5"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
