import { FC, useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getProfileData } from '../redux'
import AuthSwiper from '../components/auth/AuthSwiper'
import Cookies from "js-cookie";

const SignInLayout: FC = () => {
    const navigate = useNavigate()

    const userData = useSelector(getProfileData)
    const token = Cookies.get('token')

    if (userData?.id || token) {
        navigate('/')
    }

    useEffect(() => {
        if (userData || token) navigate('/')
    }, [userData, token])

    return (
        <main>
            <section className="auth__content">
                <div className="auth__content_left">
                    <Outlet />
                </div>
                <div className="auth__content_right">
                    <AuthSwiper />
                </div>
            </section>
        </main>
    )
}
export default SignInLayout
