import axios, { AxiosResponse } from 'axios'
import {
    IApiHandlerParams,
    IApiMethods,
    IRequestConfig,
    TApiResponse,
    TRequestMethods,
} from './interface'
import {
    activateLoading,
    getFullConfig,
    getRequestUrl,
    handleErrorResponse,
    hideLoading,
    requestHandler,
} from './handler'
import './requester-setup'

const handleRequest = (
    promise: Promise<AxiosResponse<TApiResponse>>,
    config?: IApiHandlerParams
): Promise<TApiResponse> =>
    new Promise<TApiResponse>((resolve) =>
        setTimeout(
            () =>
                resolve(
                    promise
                        .then((response) => requestHandler(response, config))
                        .catch((error) => handleErrorResponse(config, error))
                        .finally(() => hideLoading(config))
                ),
            (config && config.delay) || 0
        )
    )

const getAxiosResponseWithMockData = async (
    data: TApiResponse
): Promise<AxiosResponse<TApiResponse>> => {
    const response: AxiosResponse<TApiResponse> = {
        data,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
    } as AxiosResponse<TApiResponse> // Type assertion
    return Promise.resolve(response)
}

export const transformData = (payload: any): FormData => {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]))

    return formData
}

const apiMethods: IApiMethods = {
    get: function (
        endpoint: string,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('get', endpoint, config)()
    },

    post: function (
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('post', endpoint, config, body)()
    },

    put: function (
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('put', endpoint, config, body)()
    },

    patch: function (
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('patch', endpoint, config, body)()
    },

    delete: function (
        endpoint: string,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('delete', endpoint, config)()
    },

    factory: function (
        method: TRequestMethods,
        endpoint: string,
        config?: IRequestConfig,
        body?: any
    ): () => Promise<TApiResponse> {
        const params: any[] = [getRequestUrl(config) + endpoint]

        const bodyUpdate = config?.handler?.formData
            ? transformData(body)
            : body

        if (body) params.push(bodyUpdate)
        if (config?.request) params.push(config.request)

        return function (this: typeof apiMethods) {
            return this.request(
                method,
                params,
                config?.handler as any,
                config?.mock
            )
        }.bind(this) // Explicitly bind `this` to the returned function
    },

    request: (
        method: TRequestMethods,
        params: any[],
        config?: IApiHandlerParams,
        mock?: any
    ): Promise<TApiResponse> => {
        activateLoading(config)

        if (mock) {
            console.table({
                MOCK_REQUEST: {
                    URL: params[0],
                    REQUEST: params[1],
                    RESPONSE: mock,
                    METHOD: method,
                },
            })
        }

        const axiosMethod = axios[method] as (
            ...args: any[]
        ) => Promise<AxiosResponse<TApiResponse>>

        if (config) params.push(config)

        return handleRequest(
            mock ? getAxiosResponseWithMockData(mock) : axiosMethod(...params),
            getFullConfig(config)
        )
    },
}

export default apiMethods
