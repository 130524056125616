import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

interface ProtectedRouteProps {
    redirectPath?: string
    children?: ReactElement | null
    user?: string
}

const ProtectedRoute: FC<ProtectedRouteProps> = (
    {
        user,
        redirectPath = '/auth',
        children
    }
) => {
    if (!Cookies.get('token')) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet/>;
};
export default ProtectedRoute;
