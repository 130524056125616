export const EmailIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25024 6.75L9.00024 9.375L12.7502 6.75" stroke="#00A7B5" strokeWidth="1.5" stroke-linecap="round"
                  strokeLinejoin="round" />
            <path
                d="M1.50024 12.75V5.25C1.50024 4.42157 2.17182 3.75 3.00024 3.75H15.0002C15.8287 3.75 16.5002 4.42157 16.5002 5.25V12.75C16.5002 13.5785 15.8287 14.25 15.0002 14.25H3.00024C2.17182 14.25 1.50024 13.5785 1.50024 12.75Z"
                stroke="#00A7B5" strokeWidth="1.5" />
        </svg>
    )
}