import { useState } from "react";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { BadgesCard, ProfileCard, StatsCard, EmployeesCard } from "../../components/layout-components";
import { PlusIcon2 } from "../../img";

export const ReferencePage = () => {
    const isMobile = window.screen.width <= 1020
    const [data, setData] = useState([
        {
            id: 1,
            title: 'Справка о непредоставлении отпуска по уходу за ребенком и н...',
            image:  '/testImages/vacation/vacation_image.png',
            status: 'Визитки',
        },
        {
            id: 2,
            title: 'Индивидуальные обстоятельства',
            image:  '/testImages/vacation/vacation_image.png',
            status: 'Визитки',
        },
        {
            id: 3,
            title: 'Индивидуальные обстоятельства',
            image: '/testImages/vacation/vacation_image.png',
            status: 'Визитки',
        },
    ])
    return (
        <MainLayoutContent
            leftComponents={
                <>
                    <ProfileCard/>
                    <StatsCard/>
                    <EmployeesCard/>
                    <BadgesCard/>
                </>
            }
        >
            <div className='reference'>
                <div className='reference-header'>
                    <h1>Справки</h1>

                    <div className='reference-buttons'>

                        {!isMobile && (
                            <div className='reference-addButton'>
                                <PlusIcon2 />
                                <p>Создать</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='reference-content'>
                    {data.map((item) => (
                        <div key={item.id} className='reference-block'>
                            <img className='reference-image' src={item.image} alt="#" />

                            <h3>{item.title}</h3>

                            <div className='reference-seperator'></div>

                            <div className='reference-status'>{item.status}</div>
                        </div>
                    ))}
                </div>

                {isMobile && (
                    <div className='reference-addButton'>
                        <PlusIcon2 />
                        <p>Создать</p>
                    </div>
                )}
            </div>
        </MainLayoutContent>
    )
}